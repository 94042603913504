import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";
import { FormGrid, Input, TextArea, Select } from "../components/Form";
import { LoaderButton } from "../components/Buttons";

export default function Event() {
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const [organisationList, setOrganisationList] = useState([]);
   const [fields, handleFieldChange] = useFormFields({
      name: "",
      description: "",
      date: "",
      publicParticipations: false,
      organisation: "",
   });

   useEffect(() => {
      async function onLoad() {
         try {
            const organisations = await API.get("main", "/organisations");
            setOrganisationList(organisations);
         } catch (error) {
            console.log(error);
         }
      }

      onLoad();
   }, []);

   async function handleSubmit(event) {
      event.preventDefault();

      if (fields.name.length < 1) {
         alert("Name can not be empty");
         return;
      }

      if (!fields.organisation) {
         fields.organisation = organisationList[0].organisationId;
      }

      setLoading(true);

      try {
         await API.post("main", "/events", {
            body: {
               name: fields.name,
               description: fields.description,
               date: fields.date,
               publicParticipations: fields.publicParticipations,
               organisation: fields.organisation,
            }
         });
         history.push("/events");
      } catch (error) {
         console.log(error);
         alert(error.message);
         setLoading(false);
      }
   }

   return (
      <FormGrid>
         <label htmlFor="name">Name:</label>
         <Input type="text" idn="name" value={fields.name} onChange={handleFieldChange} />
         <label htmlFor="description">Description:</label>
         <TextArea idn="description" onChange={handleFieldChange}>{fields.description}</TextArea>
         <label htmlFor="description">Date:</label>
         <Input type="date" idn="date" value={fields.date} onChange={handleFieldChange} />
         <label htmlFor="publicParticipations">Public participations:</label>
         <Select idn="publicParticipations" value={fields.publicParticipations} onChange={handleFieldChange}>
            <option value={false}>No</option>
            <option value={true}>Yes</option>
         </Select>
         <label htmlFor="organisation">Organisation:</label>
         <Select idn="organisation" value={fields.organisation} onChange={handleFieldChange}>
            {organisationList.map((organisation) => {
               return (
                  <option value={organisation.organisationId} key={organisation.organisationId}>
                     {organisation.name}
                  </option>
               );
            })}
         </Select>
         <div></div>
         <LoaderButton onClick={handleSubmit} loading={loading}>Create</LoaderButton>
      </FormGrid>
   );
}