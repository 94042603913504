import { PrinterIcon } from "@heroicons/react/solid";
import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollapseAllButton, CollapseButton, ExpandAllButton, ExpandButton, IconButton } from "../components/Buttons";
import ExpanderBox from "../components/ExpanderBox";
import { Select } from "../components/Form";
import { Table, TableBody, TableCell, TableGroupRow, TableHeadCellFake, TableHeadFake, TableSpaceRow, ToolCell } from "../components/Table";
import { H3 } from "../components/Typography";
import { printGroupList, printScoreCard } from "../lib/pdfLib";
import { sortByBowCategory, sortByClub, sortByDate, sortById, sortByName, sortByStartGroup } from "../lib/utilLib";

export default function StartGroups() {
    const numberOfGroups = 100;
    const { eventId } = useParams();
    const [participations, setParticipations] = useState([]);
    const [grouped, setGrouped] = useState({});
    const [ungrouped, setUngrouped] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [collapseAll, setCollapseAll] = useState(false);
    const [collapseState, setCollapseState] = useState({});
    const [sort, setSort] = useState("name");
    const [event, setEvent] = useState();

    useEffect(() => {
        async function onLoad() {
            try {
                const result = await API.get("main", "/events/" + eventId + "/participations");
                setParticipations(result);
                setCollapseState(result.reduce((previous, current) => {
                    if (!previous[current.startGroup]) {
                        return {
                            ...previous,
                            [current.startGroup]: {
                                collapsed: false,
                            },
                        };
                    }
                    return previous;
                }, {}));
                setGrouped(filterGrouped(result));
                setUngrouped(filterUngrouped(result));
                const event = await API.get("main", "/events/" + eventId);
                setEvent(event);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        onLoad();
    }, [eventId]);

    function filterGrouped(participations) {
        const filtered = participations.filter(participation => {
            if (participation.startGroup) {
                return true;
            }
            return false;
        }).sort(sortByStartGroup);

        return filtered.reduce((acc, current) => {
            if (!acc[current.startGroup]) {
                acc[current.startGroup] = {
                    collapsed: false,
                    members: [],
                };
            }
            acc[current.startGroup].members.push(current);
            return acc;
        }, {});
    }

    function filterUngrouped(participations) {
        return participations.filter(participation => {
            if (participation.startGroup || participation.waitList) {
                return false;
            }
            return true;
        }).sort(sortByName);
    }

    async function changeGroup(e, participation) {
        setUpdating(true);

        const startGroup = parseInt(e.target.value);

        try {
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    startGroup: startGroup,
                },
            });
        } catch (error) {
            console.log(error);
            alert(error.message);
            setUpdating(false);
            return;
        }

        participation.startGroup = startGroup;
        setGrouped(filterGrouped(participations));
        setUngrouped(filterUngrouped(participations));

        if (!collapseState[startGroup]) {
            setCollapseState({
                ...collapseState,
                [startGroup]: {
                    collapse: false,
                },
            });
        }

        setUpdating(false);
    }

    async function changeGroupCaptain(participation) {
        const captains = grouped[participation.startGroup].members.filter(pItem => pItem.groupCaptain);
        if (captains.length >= 1 && !participation.groupCaptain) {
            alert("Nur ein Gruppenkapitän pro Gruppe erlaubt");
            return;
        }

        setUpdating(true);

        try {
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    groupCaptain: !participation.groupCaptain,
                },
            });
        } catch (error) {
            console.log(error);
            alert(error.message);
            setUpdating(false);
            return;
        }

        participation.groupCaptain = !participation.groupCaptain;
        setGrouped(filterGrouped(participations));

        setUpdating(false);
    }

    async function changeScribe(participation) {
        const scribes = grouped[participation.startGroup].members.filter(pItem => pItem.scribe);
        if (scribes.length >= 2 && !participation.scribe) {
            alert("Nur zwei Schreiber pro Gruppe erlaubt");
            return;
        }

        setUpdating(true);

        try {
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    scribe: !participation.scribe,
                },
            });
        } catch (error) {
            console.log(error);
            alert(error.message);
            setUpdating(false);
            return;
        }

        participation.scribe = !participation.scribe;
        setGrouped(filterGrouped(participations));

        setUpdating(false);
    }

    function sortUngrouped(e) {
        switch (e.target.value) {
            default:
            case "name":
                setUngrouped(ungrouped.sort(sortByName));
                break;
            case "category":
                setUngrouped(ungrouped.sort(sortByBowCategory));
                break;
            case "date":
                setUngrouped(ungrouped.sort(sortByDate));
                break;
            case "id":
                setUngrouped(ungrouped.sort(sortById));
                break;
            case "club":
                setUngrouped(ungrouped.sort(sortByClub));
                break;
        }
        setSort(e.target.value);
    }

    async function printPdf(type, grouped, groupNumber, e) {
        if (!["scorecard", "scorecards", "grouplist"].includes(type)) {
            console.log("Unknown pdf type " + type)
            return
        }
        try {
            //const logoUrl = "organisations/" + event.eventId + "/logo.png"
            const logoUrl = `organisations/${event.organisationId}/logo.png`;
            const s3Response = await Storage.get(logoUrl, {
                level: "public",
                download: true,
            });
            const logo = await s3Response.Body.arrayBuffer();
            const bytes = type.startsWith("scorecard") ? await printScoreCard(grouped, groupNumber, event) : await printGroupList(grouped, event, logo);
            const url = URL.createObjectURL(new Blob([bytes]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `${type}.pdf`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
        }
    }

    function collapseAllGroups(collapse) {
        setCollapseAll(collapse);
        setCollapseState(Object.keys(collapseState).reduce((previous, current) => ({
            ...previous,
            [current]: {
                collapsed: collapse,
            }
        }), {}));
    }

    const tableHead = <TableHeadFake>
        <TableHeadCellFake title="Anmeldungsnummer">#</TableHeadCellFake>
        <TableHeadCellFake>Name</TableHeadCellFake>
        <TableHeadCellFake title="Sprache">S</TableHeadCellFake>
        <TableHeadCellFake>Alter</TableHeadCellFake>
        <TableHeadCellFake>Kategorie</TableHeadCellFake>
        <TableHeadCellFake>Club</TableHeadCellFake>
        <TableHeadCellFake title="Gruppenwunsch (# eines anderen Teilnehmers)">GW</TableHeadCellFake>
        <TableHeadCellFake>Bemerkungen</TableHeadCellFake>
        <TableHeadCellFake>FAAS #</TableHeadCellFake>
        <TableHeadCellFake title="FAAS Target Captain">TC</TableHeadCellFake>
        <TableHeadCellFake title="Gruppenkapitän">GC</TableHeadCellFake>
        <TableHeadCellFake title="Schreiber">SC</TableHeadCellFake>
        <TableHeadCellFake></TableHeadCellFake>
    </TableHeadFake>;

    const groupOptions = [...Array(numberOfGroups).keys()].map(key => (
        <option key={key} value={key}>{key !== 0 ? key : "?"}</option>
    ));

    const currentYear = (new Date()).getFullYear();
    const ageColors = { "text-orange-500": 60, "text-red-500": 70 };
    function tableCells(participation, key) {
        const age = currentYear - Number(participation.birthday.split("-")[0]);
        const ageColor = Object.keys(ageColors).reduce((previous, current) => {
            if (age >= ageColors[current]) {
                return current
            }
            return previous
        }, "")
        return <tr key={participation.participationId} className={key % 2 ? "bg-gray-100" : ""}>
            <TableCell>{participation.participationId}</TableCell>
            <TableCell title={participation.lastName + " " + participation.firstName}>
                <ExpanderBox maxWidth="max-w-4xs">{participation.lastName + " " + participation.firstName}</ExpanderBox>
            </TableCell>
            <TableCell>{participation.language}</TableCell>
            <TableCell><span className={ageColor}>{age}</span></TableCell>
            <TableCell>{participation.age + participation.gender + participation.category}</TableCell>
            <TableCell title={participation.club}>
                <ExpanderBox maxWidth="max-w-4xs">{participation.club}</ExpanderBox>
            </TableCell>
            <TableCell title={participation.group}>
                <ExpanderBox maxWidth="max-w-4xs">{participation.group}</ExpanderBox>
            </TableCell>
            <TableCell title={participation.notes}>
                <ExpanderBox maxWidth="max-w-4xs">{participation.notes}</ExpanderBox>
            </TableCell>
            <TableCell>{participation.faasId}</TableCell>
            <TableCell>{participation.faasCaptain}</TableCell>
            <TableCell>
                {participation.startGroup > 0 &&
                    <Select
                        idn={"groupCaptain-" + key}
                        value={participation.groupCaptain}
                        disabled={updating || participation.waitList}
                        onChange={(e) => changeGroupCaptain(participation)}
                        className="w-16"
                    >
                        <option value={false}></option>
                        <option value={true}>Y</option>
                    </Select>
                }
            </TableCell>
            <TableCell>
                {participation.startGroup > 0 &&
                    <Select
                        idn={"scribe-" + key}
                        value={participation.scribe}
                        disabled={updating || participation.waitList}
                        onChange={(e) => changeScribe(participation)}
                        className="w-16"
                    >
                        <option value={false}></option>
                        <option value={true}>Y</option>
                    </Select>
                }
            </TableCell>
            <ToolCell>
                <Select
                    idn={"group-" + key}
                    value={participation.startGroup}
                    disabled={updating || participation.waitList}
                    onChange={(e) => changeGroup(e, participation)}
                >
                    {groupOptions}
                </Select>
            </ToolCell>
        </tr>;
    }

    return <>
        <H3>Groups</H3>
        <div className="mb-2">
            {!collapseAll ? (
                <CollapseAllButton onClick={() => collapseAllGroups(true)}>Collapse all</CollapseAllButton>
            ) : (
                <ExpandAllButton onClick={() => collapseAllGroups(false)}>Expand all</ExpandAllButton>
            )}
            <IconButton
                onClick={(e) => printPdf("scorecards", grouped, false, e)}
                disabled={loading}
                className="ml-2">
                <PrinterIcon className="inline h-5" />Alle Scorecards drucken
            </IconButton>
            <IconButton
                onClick={(e) => printPdf("grouplist", participations, false, e)}
                disabled={loading}
                className="ml-2">
                <PrinterIcon className="inline h-5" />Gruppenliste drucken
            </IconButton>
        </div>
        <Table className="mb-4">
            {Object.keys(grouped).length !== 0 ? (
                <>
                    {Object.keys(grouped).map((groupNumber) => (
                        <TableBody key={"group-" + groupNumber}>
                            <TableGroupRow colSpan="10">
                                {(!collapseState[groupNumber] || !collapseState[groupNumber].collapsed) ? (
                                    <CollapseButton onClick={(e) => {
                                        setCollapseState({
                                            ...collapseState,
                                            [groupNumber]: {
                                                collapsed: true,
                                            },
                                        });
                                    }} />
                                ) : (
                                    <ExpandButton onClick={(e) => {
                                        setCollapseState({
                                            ...collapseState,
                                            [groupNumber]: {
                                                collapsed: false,
                                            },
                                        });
                                    }} />
                                )}
                                Gruppe {groupNumber}
                                <IconButton
                                    onClick={(e) => printPdf("scorecard", grouped, groupNumber, e)}
                                    disabled={loading}
                                    className="ml-2">
                                    <PrinterIcon className="inline h-5" />Scorecard drucken
                                </IconButton>
                            </TableGroupRow>
                            {(!collapseState[groupNumber] || !collapseState[groupNumber].collapsed) &&
                                <>
                                    {tableHead}
                                    {grouped[groupNumber].members.map((participation, key) => (
                                        tableCells(participation, key)
                                    ))}
                                    <TableSpaceRow />
                                </>
                            }
                        </TableBody>
                    ))}
                </>
            ) : (
                <TableBody>
                    <TableGroupRow colSpan="10">Startgruppen</TableGroupRow>
                    <tr><td colSpan="10">{loading ? "Loading ..." : "No start groups found"}</td></tr>
                </TableBody>
            )}
            {ungrouped.length > 0 ? (
                <>
                    <TableBody>
                        <TableGroupRow colSpan="10">Ohne Gruppe</TableGroupRow>
                        <tr><td colSpan="10">
                            Sort: <Select idn="sort" value={sort} onChange={sortUngrouped}>
                                <option value="name">Name</option>
                                <option value="id">Id</option>
                                <option value="category">Category</option>
                                <option value="club">Club</option>
                                <option value="date">Date</option>
                            </Select>
                        </td></tr>
                        {tableHead}
                        {ungrouped.map((participation, key) => (
                            tableCells(participation, key)
                        ))}
                    </TableBody>
                </>
            ) : (
                <TableBody>
                    <TableGroupRow colSpan="10">Ohne Gruppe</TableGroupRow>
                    <tr><td colSpan="10">{loading ? "Loading ..." : "No participations found"}</td></tr>
                </TableBody>
            )}
        </Table>
    </>;
}