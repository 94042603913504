import { PrinterIcon } from "@heroicons/react/solid";
import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconButton } from "../components/Buttons";
import { SelectAge, SelectCategory, SelectGender } from "../components/Form";
import { Table, TableBody, TableCell, TableGroupRow, TableHeadCellFake, TableHeadFake, TableSpaceRow, ToolCell } from "../components/Table";
import { H3 } from "../components/Typography";
import { printCategoryList, printRatedCategories } from "../lib/pdfLib";
import { sortByRatedCategory } from "../lib/utilLib";

export default function Categories() {
    const { eventId } = useParams();
    const [participations, setParticipations] = useState([]);
    const [grouped, setGrouped] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [event, setEvent] = useState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        async function onLoad() {
            try {
                const result = await API.get("main", `/events/${eventId}/participations`);
                setParticipations(result);
                setGrouped(groupParticipations(result));
                const event = await API.get("main", `/events/${eventId}`);
                setEvent(event);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        if (!hasLoaded) {
            setHasLoaded(true)
            onLoad();
        }
    });

    function groupParticipations(participations) {
        return participations.sort(sortByRatedCategory).reduce((categoryMap, participation) => {
            const category = getCategory(participation);
            if (!categoryMap[category]) {
                categoryMap[category] = [];
            }
            categoryMap[category].push(participation);
            return categoryMap;
        }, {});
    }

    function getCategory(participation) {
        let category = participation.ratedAge + participation.ratedGender + participation.ratedCategory;
        if (!category) {
            category = participation.age + participation.gender + participation.category;
        }
        return category;
    }

    async function changeField(e, field, participation) {
        setUpdating(true);

        const oldValue = participation[field];

        try {
            participation[field] = e.target.value;
            setParticipations(participations);
            setGrouped(groupParticipations(participations));
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    [field]: e.target.value,
                },
            });
        } catch (e) {
            participation[field] = oldValue;
            setParticipations(participations);
            setGrouped(groupParticipations(participations));
            console.log(e);
            alert(e.message);
        } finally {
            setUpdating(false);
        }
    }

    async function printPdf(type, participations) {
        try {
            //const logoUrl = "organisations/" + event.eventId + "/logo.png"
            const logoUrl = `organisations/${event.organisationId}/logo.png`;
            const s3Response = await Storage.get(logoUrl, {
                level: "public",
                download: true,
            });
            const logo = await s3Response.Body.arrayBuffer();
            const bytes = type === "category-list" ? await printCategoryList(participations, event, logo) : await printRatedCategories(participations, event, logo);
            const url = URL.createObjectURL(new Blob([bytes]));
            const link = document.createElement("a");
            link.href = url;
            link.download = `participations-categories.pdf`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
        }
    }

    async function changeRatedCategory(category) {
        let oldState = true;
        if (event.ratedCategories && event.ratedCategories.hasOwnProperty(category)) {
            oldState = event.ratedCategories[category];
        }

        const newEvent = {
            ...event,
            ratedCategories: {
                ...event.ratedCategories,
                [category]: !oldState,
            },
        };

        // TODO: Send new state to server
        setUpdating(true);
        try {
            await API.put("main", `/events`, {
                body: newEvent,
            });
            setEvent(newEvent);
        } catch (e) {
            console.log(e);
            alert(e.message);
        } finally {
            setUpdating(false);
        }
    }

    function getRatedCategory(category) {
        if (!event.ratedCategories || !event.ratedCategories.hasOwnProperty(category)) {
            return true;
        }

        return event.ratedCategories[category];
    }

    return <>
        <H3>Categories</H3>
        <div className="mb-2">
            <IconButton
                onClick={() => printPdf("category-list", participations)}
                disabled={loading}
                className="ml-2">
                <PrinterIcon className="inline h-5" />Teilnehmer nach Kategorie drucken
            </IconButton>
            <IconButton
                onClick={() => printPdf("rated-categories", participations)}
                disabled={loading}
                className="ml-2">
                <PrinterIcon className="inline h-5" />Wertungskategorien drucken
            </IconButton>
        </div>
        <Table className="mb-4">
            {(Object.keys(grouped).length > 0) && event ? (
                <>
                    {Object.keys(grouped).map((category) => (
                        <TableBody key={"category-" + category}>
                            <tr>
                                <td colSpan="3" className="font-bold">
                                    <span style={{ marginRight: 10 }}>
                                        <input
                                            type="checkbox"
                                            onChange={() => changeRatedCategory(category)}
                                            checked={getRatedCategory(category)}
                                        />
                                    </span>
                                    {!getRatedCategory(category)
                                        ? <span style={{ textDecoration: "line-through" }}>{category}</span>
                                        : category
                                    }
                                </td>
                                <td>Anzahl: {grouped[category].length}</td>
                            </tr>
                            <TableHeadFake>
                                <TableHeadCellFake title="Anmeldungsnummer">#</TableHeadCellFake>
                                <TableHeadCellFake>Name</TableHeadCellFake>
                                <TableHeadCellFake>Anmeldung</TableHeadCellFake>
                                <TableHeadCellFake>Einteilung</TableHeadCellFake>
                            </TableHeadFake>
                            {grouped[category].map((participation, key) => (
                                <tr
                                    key={participation.participationId}
                                    className={key % 2 ? "bg-gray-100" : ""}
                                    style={{ color: getRatedCategory(category) ? "black" : "gray" }}
                                >
                                    <TableCell>{participation.participationId}</TableCell>
                                    <TableCell>
                                        {participation.lastName + " " + participation.firstName}
                                    </TableCell>
                                    <TableCell>{participation.age + participation.gender + participation.category}</TableCell>
                                    <ToolCell>
                                        <SelectAge
                                            idn={"ratedAge-" + key}
                                            value={participation.ratedAge}
                                            disabled={updating || participation.waitList}
                                            onChange={(e) => changeField(e, "ratedAge", participation)}
                                        />
                                        <SelectGender
                                            idn={"ratedGender-" + key}
                                            value={participation.ratedGender}
                                            disabled={updating || participation.waitList}
                                            onChange={(e) => changeField(e, "ratedGender", participation)}
                                        />
                                        <SelectCategory
                                            idn={"ratedCategory-" + key}
                                            value={participation.ratedCategory}
                                            disabled={updating || participation.waitList}
                                            onChange={(e) => changeField(e, "ratedCategory", participation)}
                                        />
                                    </ToolCell>
                                </tr>
                            ))}
                            <TableSpaceRow />
                        </TableBody>
                    ))}
                </>
            ) : (
                <TableBody>
                    <TableGroupRow colSpan="10">Kategorien</TableGroupRow>
                    <tr><td colSpan="10">{loading ? "Loading ..." : "No participations found"}</td></tr>
                </TableBody>
            )}
        </Table>
    </>;
}