import { API } from "aws-amplify";
import { useState } from "react";
import { Button, LoaderButton } from "./Buttons";
import { Select } from "./Form";

export function Score(props) {
   const [loading, setLoading] = useState(false);
   const [score, setScore] = useState(props.score.seenPosts ? props.score : { ...props.score, seenPosts: [] });
   const [confirmed, setConfirmed] = useState(false);
   const posts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];

   function start() {
      const startPost = score.scribe.startGroup - 1;
      setScore({
         ...score,
         state: startPost,
      });
   }

   function changePost(e) {
      const newScore = {
         ...score,
         state: Number(e.target.value),
         seenPosts: score.seenPosts.includes(score.state) ? score.seenPosts : [...score.seenPosts, score.state],
      };
      setScore(newScore);
      localStorage.setItem("score", JSON.stringify(newScore));
   }

   function next() {
      const nextState = (score.state + 1) % 28;
      const finishState = score.seenPosts.length === 28;
      const newScore = {
         ...score,
         state: finishState ? "confirm" : nextState,
         seenPosts: score.seenPosts.includes(score.state) ? score.seenPosts : [...score.seenPosts, score.state],
      };
      setScore(newScore);
      localStorage.setItem("score", JSON.stringify(newScore));
   }

   function back() {
      const newScore = {
         ...score,
         state: 0,
      };
      setScore(newScore);
      setConfirmed(false);
      localStorage.setItem("score", JSON.stringify(newScore));

   }

   async function finish() {
      const body = {
         scribe: score.scribe.participationId,
         squad: Object.keys(score.squad).map(id => {
            const member = score.squad[id];
            return {
               id: member.participationId,
               results: member.results,
               score: updateScore(member),
               superspots: updateSuperspots(member),
               misses: updateMisses(member),
            };
         }),
      };

      try {
         setLoading(true);
         const result = await API.post("main", `/public/participations`, {
            body,
         });
         if (!result) {
            return alert(texts.unknown_failure[lang]);
         }

         const newScore = {
            ...score,
            state: "finish",
         };
         setScore(newScore);
         localStorage.setItem("score", JSON.stringify(newScore));
      } catch (error) {
         alert(JSON.stringify(error));
      } finally {
         setLoading(false);
      }
   }

   function onChange(e, member, score, arrow) {
      const points = Number(e.target.value);

      const defaultPoints = {
         0: "",
         1: "",
      };

      const results = member.results ? {
         ...member.results,
         [score.state]: {
            ...defaultPoints,
            ...member.results[score.state],
            [arrow]: points,
         },
      } : {
         [score.state]: {
            ...defaultPoints,
            [arrow]: points,
         },
      };

      const newScore = {
         ...score,
         squad: {
            ...score.squad,
            [member.participationId]: {
               participationId: member.participationId,
               firstName: member.firstName,
               lastName: member.lastName,
               results,
            },
         },
      };
      setScore(newScore);
      localStorage.setItem("score", JSON.stringify(newScore));
   }

   function updateScore(member) {
      if (!member.results) {
         return 0;
      }
      return Object.keys(member.results).reduce((sum, picket) => {
         return sum + Object.keys(member.results[picket]).reduce((subSum, arrow) => {
            const points = member.results[picket][arrow];
            return subSum + (Number.isInteger(points) ? points : 0);
         }, 0);
      }, 0);
   }

   function updateMisses(member) {
      if (!member.results) {
         return 0;
      }
      return Object.keys(member.results).reduce((sum, picket) => {
         if (member.results[picket]) {
            const p = member.results[picket];
            sum += Object.keys(p).filter(key => p[key] === 0).length;
         }
         return sum;
      }, 0);
   }

   function updateSuperspots(member) {
      if (!member.results) {
         return 0;
      }
      return Object.keys(member.results).reduce((sum, picket) => {
         if (member.results[picket]) {
            const p = member.results[picket];
            sum += Object.keys(p).filter(key => p[key] === 10).length;
         }
         return sum;
      }, 0);
   }

   const lang = score.scribe.language;
   const texts = {
      greeting: {
         de: "Hallo",
         fr: "Bonjour",
      },
      start_explanation: {
         de: "Mit diesem Online-Tool kannst du die Resultate bequem per Smartphone eintragen und am Ende direkt ans Turnierbüro senden. Dies verkürzt die Wartezeit nach Turnierschluss bis zur Rangverkündigung. Eine Internetverbindung wird nur am Schluss zur Übertragung der Resultate benötigt.",
         fr: "Grâce à cet outil en ligne, tu peux facilement saisir les résultats sur ton smartphone et les envoyer directement au bureau du tournoi à la fin. Cela permet de réduire le temps d'attente après la fin du tournoi jusqu'à la proclamation des résultats. Une connexion Internet n'est nécessaire qu'à la fin pour la transmission des résultats.",
      },
      start_confirmation: {
         de: "Durch das Starten des Tools bestätigst du, dass du oben genannte Person bist. Bei Fragen bitte beim Turnierbüro vorbeikommen.",
         fr: "En lançant l'outil, tu confirmes que tu es bien la personne mentionnée ci-dessus. Si tu as des questions, passe au bureau du tournoi.",
      },
      start_button: {
         de: "Starten",
         fr: "Lancer",
      },
      confirmation: {
         de: "Ich bestätige, dass ich alle untenstehenden Resultate wahrheitsgemäss eingetragen, diese den Schützen gezeigt und mit dem zweiten Schreiber abgeglichen habe.",
         fr: "Je confirme que j'ai inscrit tous les résultats ci-dessus conformément à la vérité, que je les ai montrés aux tireurs et que je les ai comparés avec ceux du deuxième scribe.",
      },
      back_button: {
         de: "Zurück",
         fr: "Retour",
      },
      send_button: {
         de: "Absenden",
         fr: "Envoyer",
      },
      success: {
         de: "Die Resultate wurden erfolgreich übertragen.",
         fr: "Les résultats ont été transférés avec succès.",
      },
      group: {
         de: "Gruppe",
         fr: "Groupe",
      },
      scribe: {
         de: "Schreiber",
         fr: "Scribe",
      },
      name: {
         de: "Name",
         fr: "Nom",
      },
      picket: {
         de: "Posten",
         fr: "Poste",
      },
      done: {
         de: "Fertig",
         fr: "Complet",
      },
      result: {
         de: "Ziel",
         fr: "But",
      },
      unknown_failure: {
         de: "Unbekannter Fehler. Bitte später nochmal versuchen oder dem Turnierbüro melden.",
         fr: "Erreur non identifiée. Merci de réessayer plus tard ou de le signaler au bureau du tournoi.",
      },
   }

   return <>
      {score.state === "start" &&
         <div className="w-80">
            <p>{texts.greeting[lang]} {score.scribe.firstName} {score.scribe.lastName}</p>
            <p className="mt-4">{texts.start_explanation[lang]}</p>
            <p className="mt-4">{texts.start_confirmation[lang]}</p>
            <Button onClick={start} className="mt-4 mx-auto">{texts.start_button[lang]}</Button>
         </div>
      }
      {score.state === "confirm" &&
         <div>
            <div className="mt-4 w-80">
               <input
                  id="confirmation-checkbox"
                  type="checkbox"
                  defaultChecked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  disabled={loading}
               /> <label for="confirmation-checkbox">{texts.confirmation[lang]}</label>
            </div>
            <div className="mt-4">
               <Button
                  onClick={back}
                  disabled={loading}
               >
                  {texts.back_button[lang]}
               </Button>
               <LoaderButton
                  onClick={finish}
                  className="ml-4 bg-green-600"
                  disabled={!confirmed}
                  loading={loading}
               >
                  {texts.send_button[lang]}
               </LoaderButton>
            </div>
            <table className="mt-4">
               {Object.keys(score.squad).map(id => {
                  const member = score.squad[id];
                  const results = member.results;
                  return <>
                     <tr key={`results-${id}-header`}>
                        <td colspan={12} className="p-2 bg-slate-200">
                           <span className="mr-4">{member.firstName} {member.lastName}</span>
                           <span className="mr-2">Σ: {updateScore(member)}</span>
                           <span className="mr-2">◎: {updateSuperspots(member)}</span>
                           <span>0: {updateMisses(member)}</span>
                        </td>
                     </tr>
                     {results && Object.keys(results).reduce((rows, picket, index) => {
                        const picketResults = results[picket];
                        const result1 = picketResults.hasOwnProperty(0) && Number.isInteger(picketResults[0]) ? picketResults[0] : "";
                        const result2 = picketResults.hasOwnProperty(0) && Number.isInteger(picketResults[1]) ? picketResults[1] : "";
                        if (picket < 7) {
                           rows.push([]);
                        }
                        rows[index % 7].push(<>
                           <td className="pr-1 text-right bg-slate-200">{++picket}</td>
                           <td className="text-right">{result1}</td>
                           <td className="pr-2 text-right">{result2}</td>
                        </>);
                        return rows;
                     }, []).map((tds, index) => (<tr key={`results-${id}-results-row-${index}`}>{tds.map(td => td)}</tr>))}
                  </>
               })}
            </table>
         </div>
      }
      {score.state === "finish" &&
         <div>
            <p className="w-80 mb-4">✅ {texts.success[lang]}</p>
            <p>{texts.group[lang]}: {score.scribe.startGroup}</p>
            <p className="mb-4">{texts.scribe[lang]}: {score.scribe.firstName} {score.scribe.lastName}</p>
            <table>
               <thead>
                  <tr>
                     <th>{texts.name[lang]}</th>
                     <th>Σ</th>
                     <th>◎</th>
                     <th>0</th>
                  </tr>
               </thead>
               <tbody>
                  {Object.keys(score.squad).map(id => {
                     const member = score.squad[id];
                     return <tr key={`results-${id}-header`}>
                        <td>{member.firstName} {member.lastName}</td>
                        <td className="pl-2 text-right">{updateScore(member)}</td>
                        <td className="pl-2 text-right">{updateSuperspots(member)}</td>
                        <td className="pl-2 text-right">{updateMisses(member)}</td>
                     </tr>
                  })}
               </tbody >
            </table>
         </div>
      }
      {Number.isInteger(score.state) &&
         <div>
            <div>
               {texts.picket[lang]}: <Select idn="picket-select" onChange={changePost} value={score.state}>
                  {posts.map(post => (<option value={post}>{post + 1}</option>))}
               </Select><Button className="ml-4" onClick={next}>{score.seenPosts.length > 27 ? `${texts.done[lang]}` : <>-&gt;</>}</Button>
            </div>
            <table>
               <thead>
                  <tr>
                     <th className="pl-2 text-left">{texts.name[lang]}</th>
                     <th className="text-right w-12">Σ</th>
                     <th className="text-right w-8">◎</th>
                     <th className="pr-2 text-right w-8">0</th>
                  </tr>
               </thead>
               <tbody>
                  {Object.keys(score.squad).map(id => {
                     return <>
                        <tr key={`${id}-header`} className="bg-slate-200">
                           <td className="pl-2">{score.squad[id].firstName} {score.squad[id].lastName}</td>
                           <td className="text-right">{updateScore(score.squad[id])}</td>
                           <td className="text-right">{updateSuperspots(score.squad[id])}</td>
                           <td className="pr-2 text-right">{updateMisses(score.squad[id])}</td>
                        </tr>
                        <tr key={`${id}-results`}>
                           <td colspan={4} className="pb-4">
                              <span className="mr-4">{texts.result[lang]} A: <Select
                                 idn={`${id}-results-${score.state}-0`}
                                 onChange={(e) => onChange(e, score.squad[id], score, 0)}
                                 value={score.squad[id].results ? (score.squad[id].results[score.state] ? score.squad[id].results[score.state][0] : "") : ""}
                              >
                                 <option value=""></option>
                                 <option value="10">10</option>
                                 <option value="8">8</option>
                                 <option value="5">5</option>
                                 <option value="0">0</option>
                              </Select></span>
                              <span>{texts.result[lang]} B: <Select
                                 idn={`${id}-results-${score.state}-0`}
                                 onChange={(e) => onChange(e, score.squad[id], score, 1)}
                                 value={score.squad[id].results ? (score.squad[id].results[score.state] ? score.squad[id].results[score.state][1] : "") : ""}
                              >
                                 <option value=""></option>
                                 <option value="10">10</option>
                                 <option value="8">8</option>
                                 <option value="5">5</option>
                                 <option value="0">0</option>
                              </Select></span>
                           </td>
                        </tr>
                     </>
                  })}
               </tbody>
            </table >
         </div>
      }
   </>
}