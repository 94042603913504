import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useFormFields } from "../lib/hooksLib";
import { useAppContext } from "../lib/contextLib";
import { validateEmail } from "../lib/utilLib";
import { LoaderButton } from "../components/Buttons";
import { FormGrid, Input } from "../components/Form";

export default function Registration() {
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const [registered, setRegistered] = useState(false);
   const { setAuthenticated } = useAppContext();
   const [fields, handleFieldChange] = useFormFields({
      email: "",
      password: "",
      password2: "",
      code: "",
   });

   async function handleSubmitRegistration(e) {
      if (!validateEmail(fields.email)) {
         alert("Email malformed");
         return;
      }

      if (fields.password !== fields.password2) {
         alert("Passwords do not match")
         return;
      }

      setLoading(true);

      try {
         await Auth.signUp({
            username: fields.email,
            password: fields.password,
         });
         setRegistered(true);
      } catch (e) {
         console.log(e);
         alert(e.message);
      } finally {
         setLoading(false);
      }
   }

   async function handleSubmitActivation(e) {
      if (fields.code.length < 1) {
         alert("Activation code is empty")
         return;
      }

      setLoading(true);

      try {
         await Auth.confirmSignUp(fields.email, fields.code);
         await Auth.signIn(fields.email, fields.password);
         setAuthenticated(true);
         history.push("/");
      } catch (e) {
         console.log(e);
         alert(e.message);
         setLoading(false);
      }
   }

   return (
      <FormGrid>
         {!registered ? (
            <>
               <label htmlFor="email">E-Mail:</label>
               <Input type="text" idn="email" value={fields.email} onChange={handleFieldChange} />
               <label htmlFor="password">Password:</label>
               <Input type="password" idn="password" value={fields.password} onChange={handleFieldChange} />
               <div></div>
               <Input type="password" idn="password2" value={fields.password2} onChange={handleFieldChange} />
               <div></div>
               <LoaderButton onClick={handleSubmitRegistration} loading={loading}>Register</LoaderButton>
            </>
         ) : (
            <>
               <label htmlFor="code">Code:</label>
               <Input type="text" idn="code" value={fields.code} onChange={handleFieldChange} />
               <div></div>
               <LoaderButton onClick={handleSubmitActivation} loading={loading}>Activate</LoaderButton>
            </>
         )}
      </FormGrid>
   );
}