import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Event from "./containers/Event";
import EventList from "./containers/EventList";
import ParticipationList from "./containers/ParticipationList";
import Organisation from "./containers/Organisation";
import Registration from "./containers/Registration";
import RegistrationConfirmation from "./containers/RegistrationConfirmation";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import NotFound from "./containers/NotFound";
import StartGroups from "./containers/StartGroups";
import Results from "./containers/Results";
import Categories from "./containers/Categories";
import Reception from "./containers/Reception";
import GpsTest from "./containers/GpsTest";

export default function Routes() {
   return (
      <Switch>
         <Route exact path="/events/new">
            <Event />
         </Route>
         <Route exact path="/events">
            <EventList />
         </Route>
         <Route exact path="/events/:eventId/participations">
            <ParticipationList />
         </Route>
         <Route exact path="/events/:eventId/groups">
            <StartGroups />
         </Route>
         <Route exact path="/events/:eventId/categories">
            <Categories />
         </Route>
         <Route exact path="/events/:eventId/reception">
            <Reception />
         </Route>
         <Route exact path="/events/:eventId/results">
            <Results />
         </Route>
         <Route exact path="/organisations/new">
            <Organisation />
         </Route>
         <Route exact path="/registration">
            <Registration />
         </Route>
         <Route exact path="/registration/confirmation">
            <RegistrationConfirmation />
         </Route>
         <Route exact path="/login">
            <Login />
         </Route>
         <Route exact path="/logout">
            <Logout />
         </Route>
         <Route exact path="/">
            <Home />
         </Route>
         <Route exact path="/experiments/gpstest">
            <GpsTest />
         </Route>
         <Route>
            <NotFound />
         </Route>
      </Switch>
   );
}