import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { LoaderButton } from "../components/Buttons";
import { FormGrid, Input } from "../components/Form";

export default function Login() {
   const [loading, setLoading] = useState(false);
   const { setAuthenticated } = useAppContext();
   const history = useHistory();
   const [fields, handleFieldChange] = useFormFields({
      email: "",
      password: ""
   });

   async function handleSubmit(e) {
      setLoading(true);

      try {
         await Auth.signIn(fields.email, fields.password);
         setAuthenticated(true);
         history.push("/");
      } catch (e) {
         console.log(e);
         alert(e.message);
         setLoading(false);
      }
   }

   return (
      <FormGrid>
         <label htmlFor="email">E-Mail:</label>
         <Input type="text" idn="email" value={fields.email} onChange={handleFieldChange} />
         <label htmlFor="password">Password:</label>
         <Input type="password" idn="password" value={fields.password} onChange={handleFieldChange} />
         <div></div>
         <LoaderButton onClick={handleSubmit} loading={loading}>Login</LoaderButton>
      </FormGrid>
   );
}