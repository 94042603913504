import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Routes from './Routes';
import Header from './components/Header';
import Scribe from "./containers/Scribe";
import { AppContext } from './lib/contextLib';

function App() {
    const [authenticated, setAuthenticated] = useState(false);
    const [authenticating, setAuthenticating] = useState(true);

    const url = window.location.href;

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            setAuthenticated(true);
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        } finally {
            setAuthenticating(false);
        }
    }

    return <>
        {url.includes("/scribe/")
            ? <Scribe />
            : <>{!authenticating && (
                <AppContext.Provider value={{ authenticated, setAuthenticated }}>
                    <div className="container mx-auto">
                        <Header />
                        <main className="mx-5 my-5">
                            <Routes />
                        </main>
                    </div>
                </AppContext.Provider>
            )}</>
        }
    </>;
}

export default App;
