import { NavLink } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import logo from '../logo.svg';

export function Navigation() {
   let { authenticated } = useAppContext();

   return (
      <nav className="mt-1">
         <ul className="flex items-center">
            <NavItem to="/" exact>
               <img className="h-8 w-auto" src={logo} alt="Convento" />
            </NavItem>
            <NavItem to="/" exact text="Home" />
            {authenticated ? (
               <>
                  <NavItem to="/events" text="Events" />
                  <NavItem to="/logout" exact text="Logout" />
               </>
            ) : (
               <>
                  {/* <NavItem to="/registration" text="Register" /> */}
                  <NavItem to="/login" exact text="Login" />
               </>
            )}
         </ul>
      </nav>
   );
}

export function NavItem(props) {
   return <li className="ml-1">
      <NavLink to={props.to} exact={props.exact} activeClassName="underline">{props.text || props.children}</NavLink>
   </li>;
}