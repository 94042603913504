import {
   ChevronDoubleDownIcon,
   ChevronDoubleUpIcon,
   ChevronDownIcon,
   ChevronUpIcon,
   ClockIcon,
   DownloadIcon,
   PencilIcon,
   TrashIcon,
   UploadIcon,
   XIcon
} from '@heroicons/react/solid';
import classnames from "classnames";
import { CSVLink } from "react-csv";

export function BaseButton(props) {
   return (
      <button type="button" onClick={props.onClick} disabled={props.disabled} className={classnames(
         "py-2",
         "px-4",
         "font-semibold",
         "rounded-lg",
         "shadow-md",
         "focus:outline-none",
         "focus:ring-2",
         "focus:ring-opacity-75",
         props.className, props.disabled ? "opacity-50" : "")}>
         {props.children}
      </button>
   );
}

export function Button(props) {
   return <BaseButton onClick={props.onClick} disabled={props.disabled} className={classnames(
      "bg-indigo-500",
      "text-white",
      "hover:bg-indigo-700",
      "focus:ring-indigo-400",
      props.className)}>
      {props.children}
   </BaseButton>
}

export function CancelButton(props) {
   return <BaseButton onClick={props.onClick} disabled={props.disabled} className={classnames(
      props.className)}>
      {props.children}
   </BaseButton>
}

export function LoaderButton(props) {
   return (
      <Button onClick={props.onClick} disabled={props.loading || props.disabled} className={`${props.className} items-center justify-center`.trim()}>
         {props.loading && <ClockIcon className="animate-pulse inline h-5" />} {props.children}
      </Button >
   );
}

export function SaveIconButton(props) {
   return (
      <IconButton onClick={props.onClick} disabled={props.loading} title="Save">
         {props.loading && <ClockIcon className="animate-pulse inline h-5" />}
         {!props.loading && <UploadIcon className="inline h-5" />}
         {props.children}
      </IconButton >
   );
}

export function CSVButton(props) {
   return <CSVLink data={props.data} filename={props.filename}>
      <DownloadIcon className="inline h-5" />{props.children}
   </CSVLink>;
}

export function IconButton(props) {
   return <button
      type="button"
      onClick={props.onClick}
      title={props.title}
      disabled={props.disabled}
      className={classnames("items-center", "justify-center", props.disabled ? "opacity-50" : "", props.className)}>
      {props.children}
   </button>;
}

export function EditButton(props) {
   return <IconButton onClick={props.onClick} title="Edit">
      <PencilIcon className="inline h-5" />
   </IconButton>;
}

export function AbortButton(props) {
   return <IconButton onClick={props.onClick} title="Cancel">
      <XIcon className="inline h-5" />
   </IconButton>;
}

export function DeleteButton(props) {
   return <IconButton onClick={props.onClick} title="Delete">
      <TrashIcon className="inline h-5" />
   </IconButton>;
}

export function ExpandButton(props) {
   return <IconButton onClick={props.onClick} title="Expand">
      <ChevronDownIcon className="inline h-5" />
   </IconButton>;
}

export function ExpandAllButton(props) {
   return <IconButton onClick={props.onClick} title="Expand all">
      <ChevronDoubleDownIcon className="inline h-5" />{props.children}
   </IconButton>;
}

export function CollapseButton(props) {
   return <IconButton onClick={props.onClick} title="Collapse">
      <ChevronUpIcon className="inline h-5" />
   </IconButton>;
}

export function CollapseAllButton(props) {
   return <IconButton onClick={props.onClick} title="Collapse all">
      <ChevronDoubleUpIcon className="inline h-5" />{props.children}
   </IconButton>;
}
