import classnames from "classnames";

export function Table(props) {
   return (
      <table className={classnames(props.className)}>
         {props.children}
      </table>
   );
}

export function TableHead(props) {
   return (
      <thead className={classnames("text-left", props.className)}>
         <tr>
            {props.children}
         </tr>
      </thead>
   );
}

export function TableHeadCell(props) {
   return (
      <th title={props.title} className={classnames("px-2", props.className)}>
         {props.children}
      </th>
   );
}

export function TableHeadFake(props) {
   return <tr className={classnames("font-bold", props.className)}>{props.children}</tr>;
}

export function TableHeadCellFake(props) {
   return (
      <td title={props.title} className={classnames("px-2 font-bold content-center", props.className)}>
         {props.children}
      </td>
   );
}

export function TableBody(props) {
   return (
      <tbody className={classnames(props.className)}>
         {props.children}
      </tbody>
   );
}

export function TableCell(props) {
   return (
      <td
         className={classnames("px-2", props.className)}
         title={props.title}>
         {props.children}
      </td>
   );
}

export function ToolCell(props) {
   return (
      <TableCell
         className={classnames("whitespace-nowrap", props.className)}>
         {props.children}
      </TableCell>
   );
}

export function TableGroupRow(props) {
   return <tr><td colSpan={props.colSpan} className="font-bold">{props.children}</td></tr>;
}

export function TableSpaceRow(props) {
   return <tr><td colSpan={props.colSpan} className="h-4"></td></tr>;
}