import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { format } from "date-fns";
import truncate from "lodash/truncate";
import { Table, TableBody, TableCell, TableGroupRow, TableHeadFake, TableHeadCellFake } from "../components/Table";
import { NavLink } from "react-router-dom";

export default function EventList() {
    const [organisations, setOrganisations] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function onLoad() {
            try {
                const organisations = (await API.get("main", "/organisations")).reduce((orgs, current) => {
                    orgs[current.organisationId] = current;
                    return orgs;
                }, {});
                setOrganisations(organisations);
                const events = await API.get("main", "/events");
                setEventList(events);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        onLoad();
    }, []);

    return (
        <>
            {/* <div>
            <NavLink to="/events/new" exact>New</NavLink>
         </div> */}
            {eventList.length > 0 ? (
                <Table>
                    {eventList.map((eventGroup, key) => {
                        return <TableBody key={"organisation-" + key}>
                            <TableGroupRow colSpan="4">{organisations[eventGroup[0].organisationId].name}</TableGroupRow>
                            <TableHeadFake>
                                <TableHeadCellFake>Name</TableHeadCellFake>
                                <TableHeadCellFake>Datum</TableHeadCellFake>
                                <TableHeadCellFake>Erstellt</TableHeadCellFake>
                                <TableHeadCellFake></TableHeadCellFake>
                            </TableHeadFake>
                            {eventGroup.map((event) => {
                                return <tr key={event.eventId}>
                                    <TableCell title={event.name}>{truncate(event.name, { length: 40 })}</TableCell>
                                    <TableCell>{format(new Date(event.eventDate), "dd.MM.yyyy")}</TableCell>
                                    <TableCell>{format(new Date(event.createdAt), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                                    <TableCell>
                                        <NavLink to={"/events/" + event.eventId + "/participations"} className="text-blue-500 hover:text-blue-300">Participations</NavLink>
                                        <NavLink to={"/events/" + event.eventId + "/groups"} className="ml-1 text-blue-500 hover:text-blue-300">Groups</NavLink>
                                        <NavLink to={"/events/" + event.eventId + "/categories"} className="ml-1 text-blue-500 hover:text-blue-300">Categories</NavLink>
                                        <NavLink to={"/events/" + event.eventId + "/reception"} className="ml-1 text-blue-500 hover:text-blue-300">Reception</NavLink>
                                        <NavLink to={"/events/" + event.eventId + "/results"} className="ml-1 text-blue-500 hover:text-blue-300">Results</NavLink>
                                    </TableCell>
                                </tr>
                            })}
                        </TableBody>
                    })}
                </Table>
            ) : (
                <p>{loading ? "Loading ..." : "No events found"}</p>
            )}
        </>
    );
}