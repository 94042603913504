
export function validateEmail(email) {
   return /^\S+@\S+\.\S+$/.test(email);
}

// TODO: Make sort functions chainable by adding a third parameter "sortFurtherFunction" and call it in equal case
export function sortById(left, right) {
   if (left.participationId < right.participationId) {
      return -1;
   }
   if (left.participationId === right.participationId) {
      return 0;
   }
   return 1;
}

export function sortByDate(left, right) {
   if (left.createdAt < right.createdAt) {
      return -1;
   }
   if (left.createdAt === right.createdAt) {
      return 0;
   }
   return 1;
}

export function sortByName(left, right) {
   if (left.lastName < right.lastName) {
      return -1;
   }
   if (left.lastName === right.lastName) {
      if (left.firstName < right.firstName) {
         return -1;
      }
      if (left.firstName === right.firstName) {
         return sortByDate(left, right);
      }
      return 1;
   }
   return 1;
}

export function sortByBirthday(left, right) {
   if (left.birthday < right.birthday) {
      return -1;
   }
   if (left.birthday === right.birthday) {
      if (left.lastName < right.lastName) {
         return -1;
      }
      if (left.lastName === right.lastName) {
         if (left.firstName < right.firstName) {
            return -1;
         }
         if (left.firstName === right.firstName) {
            return sortByDate(left, right);
         }
         return 1;
      }
      return 1;
   }
   return 1;
}

export function sortByBowCategory(left, right) {
   if (left.category < right.category) {
      return -1;
   }
   if (left.category === right.category) {
      if (left.gender < right.gender) {
         return -1;
      }
      if (left.gender === right.gender) {
         if (left.age < right.age) {
            return -1;
         }
         if (left.age === right.age) {
            return sortByName(left, right);
         }
         return 1;
      }
      return 1;
   }
   return 1;
}

export function sortByRatedCategory(left, right) {
   if (left.ratedCategory < right.ratedCategory) {
      return -1;
   }
   if (left.ratedCategory === right.ratedCategory) {
      if (left.ratedGender < right.ratedGender) {
         return -1;
      }
      if (left.ratedGender === right.ratedGender) {
         if (left.ratedAge < right.ratedAge) {
            return -1;
         }
         if (left.ratedAge === right.ratedAge) {
            return sortByName(left, right);
         }
         return 1;
      }
      return 1;
   }
   return 1;
}

export function sortByClub(left, right) {
   if (left.club.toLowerCase() < right.club.toLowerCase()) {
      return -1;
   }
   if (left.club.toLowerCase() === right.club.toLowerCase()) {
      return sortByName(left, right);
   }
   return 1;
}

export function sortByPaid(left, right) {
   if (left.paid < right.paid) {
      return -1;
   }
   if (left.paid === right.paid) {
      return sortByName(left, right);
   }
   return 1;
}

export function sortByPresent(left, right) {
   if (left.present === undefined) {
      left.present = "N";
   }
   if (right.present === undefined) {
      right.present = "N";
   }

   if (left.present < right.present) {
      return -1;
   }
   if (left.present === right.present) {
      return sortByName(left, right);
   }
   return 1;
}

export function sortByStartGroup(left, right) {
   if (left.startGroup) {
      if (right.startGroup) {
         if (left.startGroup < right.startGroup) {
            return -1;
         }
         if (left.startGroup === right.startGroup) {
            return sortByFunction(left, right);
         }
         return 1;
      }
      return -1;
   }
   if (right.startGroup) {
      return 1;
   }
   return sortByFunction(left, right);
}

export function sortByFunction(left, right) {
   if (left.groupCaptain) {
      return -1;
   }
   if (right.groupCaptain) {
      return 1;
   }
   if (left.scribe) {
      return -1;
   }
   if (right.scribe) {
      return 1;
   }
   return sortByName(left, right);
}

export function sortByWaitList(left, right) {
   if (left.waitList < right.waitList) {
      return -1;
   }
   if (left.waitList === right.waitList) {
      return sortByDate(left, right);
   }
   return 1;
}

export function sortForLeaderBoard(left, right) {
   if (left.ratedCategory < right.ratedCategory) {
      return -1;
   }
   if (left.ratedCategory === right.ratedCategory) {
      if (left.ratedGender < right.ratedGender) {
         return -1;
      }
      if (left.ratedGender === right.ratedGender) {
         if (left.ratedAge < right.ratedAge) {
            return -1;
         }
         if (left.ratedAge === right.ratedAge) {
            return sortByScore(left, right);
         }
         return 1;
      }
      return 1;
   }
   return 1;
}

export function sortByScore(left, right, scoreOnly = false) {
   if (parseInt(left.score) > parseInt(right.score)) {
      return -1;
   }
   if (parseInt(left.score) === parseInt(right.score)) {
      if (parseInt(left.superspots) > parseInt(right.superspots)) {
         return -1;
      }
      if (parseInt(left.superspots) === parseInt(right.superspots)) {
         if (parseInt(left.misses) < parseInt(right.misses)) {
            return -1;
         }
         if (parseInt(left.misses) === parseInt(right.misses)) {
            return scoreOnly ? 0 : sortByName(left, right);
         }
         return 1;
      }
      return 1;
   }
   return 1;
}

export function filterParticipations(participation, filter) {
   if (!filter) {
      return true;
   }

   if (!isNaN(filter) && participation.startGroup === parseInt(filter)) {
      return true;
   }

   if (participation.firstName.toLowerCase().includes(filter.toLowerCase())) {
      return true;
   }
   if (participation.lastName.toLowerCase().includes(filter.toLowerCase())) {
      return true;
   }
   if (participation.participationId.toLowerCase().startsWith(filter.toLowerCase())) {
      return true;
   }

   return false;
};

// Duplicate code in backend nanoid.js
const alphabet = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const mangled = 'QWE1RTZ2UIO3PAS4DFG5HJK6LYX7CVB8NM90';

// Duplicate code in backend nanoid.js
export function map(id) {
   let remapped = []
   for (let index = 0; index < id.length; index++) {
      const character = id.charAt(index);
      const position = alphabet.indexOf(character);
      remapped.push(mangled.charAt(position))
   }
   return remapped.join("");
}

// Duplicate code in backend nanoid.js
export function unmap(mapped) {
   let id = []
   for (let index = 0; index < mapped.length; index++) {
      const character = mapped.charAt(index);
      const position = mangled.indexOf(character);
      id.push(alphabet.charAt(position))
   }
   return id.join("");
}