import { ZoomOutIcon } from '@heroicons/react/solid';
import { useState } from "react";
import classnames from "classnames";

export default function ExpanderBox(props) {
   const [clicked, setClicked] = useState(false);

   return <>
      {clicked ? (
         <div onClick={() => setClicked(false)}
            className={props.className}>
            <ZoomOutIcon className="inline h-5"></ZoomOutIcon>{props.children}
         </div>
      ) : (
         <div onClick={() => setClicked(true)}
            className={classnames("overflow-hidden", "whitespace-nowrap", props.maxWidth, props.className)}>
            {props.children}
         </div>
      )}
   </>;
}