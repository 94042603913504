import { PrinterIcon } from "@heroicons/react/solid";
import { API, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CancelButton, IconButton, LoaderButton } from "../components/Buttons";
import ExpanderBox from "../components/ExpanderBox";
import { Input, Select } from "../components/Form";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, ToolCell } from "../components/Table";
import { H3 } from "../components/Typography";
import { printLeaderBoard } from "../lib/pdfLib";
import { filterParticipations, sortByName, sortByStartGroup, sortForLeaderBoard } from "../lib/utilLib";

export default function Results() {
   const { eventId } = useParams();
   const [participations, setParticipations] = useState([]);
   const [event, setEvent] = useState();
   const [loading, setLoading] = useState(true);
   const [filter, setFilter] = useState("");
   const [sort, setSort] = useState("name");

   useEffect(() => {
      async function onLoad() {
         try {
            const event = await API.get("main", "/events/" + eventId);
            setEvent(event);
            const result = await API.get("main", "/events/" + eventId + "/participations");
            const pItems = result.map(participation => {
               return {
                  ...participation,
                  oldValues: {
                     score: participation.score,
                     superspots: participation.superspots,
                     misses: participation.misses,
                  },
               }
            });
            setParticipations(pItems.sort(sortByName));
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false);
         }
      }

      onLoad();
   }, [eventId]);

   function setValue(participation, field, e) {
      setParticipations(participations.map(pItem => {
         if (pItem === participation) {
            return {
               ...participation,
               [field]: e.target.value,
            };
         }
         return pItem;
      }));
   }

   async function updateResults(participation) {
      try {
         if (!validateForm(participation)) {
            return;
         }
         setLoading(true);
         await API.put("main", "/participations/results", {
            body: {
               participationId: participation.participationId,
               score: participation.score,
               superspots: participation.superspots,
               misses: participation.misses,
            },
         });
         setParticipations(participations.map(pItem => {
            if (pItem === participation) {
               return {
                  ...participation,
                  oldValues: {
                     score: participation.score,
                     superspots: participation.superspots,
                     misses: participation.misses,
                  },
               };
            }
            return pItem;
         }));
      } catch (e) {
         console.log(e);
         alert(e.message);
      } finally {
         setLoading(false);
      }
   }

   function validateForm(participation) {
      if (participation.score !== "" && isNaN(parseInt(participation.score))) {
         alert("Anzahl Punkte muss eine Ganzzahl sein");
         return false;
      }
      if (participation.superspots !== "" && isNaN(parseInt(participation.superspots))) {
         alert("Anzahl Zehner muss eine Ganzzahl sein");
         return false;
      }
      if (participation.misses !== "" && isNaN(parseInt(participation.misses))) {
         alert("Anzahl Nuller muss eine Ganzzahl sein");
         return false;
      }
      return true;
   }

   async function printPdf(participations, abbreviated) {
      const sorted = participations.filter(pItem => {
         if (pItem.present !== "Y" || !pItem.hasOwnProperty("score") || !pItem.hasOwnProperty("superspots") || !pItem.hasOwnProperty("misses")) {
            return false;
         }
         return true;
      }).sort(sortForLeaderBoard);

      const grouped = sorted.reduce((previous, current) => {
         const category = current.ratedAge + current.ratedGender + current.ratedCategory;

         if (!previous[category]) {
            return {
               ...previous,
               [category]: {
                  participations: [
                     current,
                  ],
               },
            };
         }

         if (abbreviated && previous[category].participations.length >= 3) {
            return previous
         }

         return {
            ...previous,
            [category]: {
               participations: [
                  ...previous[category].participations,
                  current,
               ],
            },
         };
      }, {});

      try {
         //const logoUrl = "organisations/" + event.eventId + "/logo.png"
         const logoUrl = `organisations/${event.organisationId}/logo.png`;
         const s3Response = await Storage.get(logoUrl, {
            level: "public",
            download: true,
         });
         const logo = await s3Response.Body.arrayBuffer();
         const bytes = await printLeaderBoard(grouped, event, logo);
         const url = URL.createObjectURL(new Blob([bytes]));
         const link = document.createElement("a");
         link.href = url;
         link.download = abbreviated ? "ranking-short.pdf" : "ranking.pdf";
         document.body.appendChild(link);
         link.click();
         link.remove();
      } catch (error) {
         console.log(error);
      }
   }

   function sortParticipations(e) {
      switch (e.target.value) {
         default:
         case "name":
            setParticipations(participations.sort(sortByName));
            break;
         case "group":
            setParticipations(participations.sort(sortByStartGroup));
            break;
      }
      setSort(e.target.value);
   }

   function getInputClass(participation, field) {
      let className = "w-14 h-6";
      if (participation[field] !== participation.oldValues[field]) {
         className += " bg-yellow-200";
      }
      return className;
   }

   function participationDataChanged(participation) {
      if (participation.score !== participation.oldValues.score) {
         return true;
      }
      if (participation.superspots !== participation.oldValues.superspots) {
         return true;
      }
      if (participation.misses !== participation.oldValues.misses) {
         return true;
      }

      return false;
   }

   function resetParticipation(participation) {
      setParticipations(participations.map(pItem => {
         if (pItem !== participation) {
            return pItem;
         }

         return {
            ...participation,
            score: participation.oldValues.score,
            superspots: participation.oldValues.superspots,
            misses: participation.oldValues.misses,
         }
      }));
   }

   return <>
      {participations.length > 0 ? (
         <form>
            <H3>Results</H3>
            <div className="mb-2">
               <IconButton
                  onClick={() => printPdf(participations)}
                  disabled={loading}
                  className="ml-2">
                  <PrinterIcon className="inline h-5" />Ranglisten drucken
               </IconButton>
               <IconButton
                  onClick={() => printPdf(participations, true)}
                  disabled={loading}
                  className="ml-2">
                  <PrinterIcon className="inline h-5" />Rangverkündigung drucken
               </IconButton>
            </div>
            <div className="mb-2">
               <label htmlFor="sort">Sort:</label>
               <Select idn="sort" value={sort} onChange={sortParticipations} className="ml-2">
                  <option value="name">Name</option>
                  <option value="group">Gruppe</option>
               </Select>
               <label htmlFor="filter" className="ml-5">Filter:</label>
               <Input idn="filter" value={filter} onChange={e => setFilter(e.target.value)} className="ml-2" />
               <CancelButton onClick={() => setFilter("")} className="ml-2">Clear</CancelButton>
            </div>
            <Table>
               <TableHead>
                  <TableHeadCell title="Anmeldungsnummer">#</TableHeadCell>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Vorname</TableHeadCell>
                  <TableHeadCell>Gruppe</TableHeadCell>
                  <TableHeadCell>Punkte</TableHeadCell>
                  <TableHeadCell>Zehner</TableHeadCell>
                  <TableHeadCell>Nuller</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
               </TableHead>
               <TableBody>
                  {participations.filter(participation => filterParticipations(participation, filter)).filter(p => p.present === "Y").map((participation, key) => {
                     return (
                        <tr key={participation.participationId} className={key % 2 ? "bg-gray-100" : ""}>
                           <TableCell>{participation.participationId}</TableCell>
                           <TableCell title={participation.lastName}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.lastName}</ExpanderBox>
                           </TableCell>
                           <TableCell title={participation.firstName}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.firstName}</ExpanderBox>
                           </TableCell>
                           <TableCell>{participation.startGroup}</TableCell>
                           <TableCell>
                              <Input
                                 idn={"participation-" + participation.participationId + "_score"}
                                 value={participation.hasOwnProperty("score") ? participation.score : ""}
                                 onChange={e => setValue(participation, "score", e)}
                                 className={getInputClass(participation, "score")}
                                 disabled={loading}
                              />
                           </TableCell>
                           <TableCell>
                              <Input
                                 idn={"participation-" + participation.participationId + "_superspots"}
                                 value={participation.hasOwnProperty("superspots") ? participation.superspots : ""}
                                 onChange={e => setValue(participation, "superspots", e)}
                                 className={getInputClass(participation, "superspots")}
                                 disabled={loading}
                              />
                           </TableCell>
                           <TableCell>
                              <Input
                                 idn={"participation-" + participation.participationId + "_misses"}
                                 value={participation.hasOwnProperty("misses") ? participation.misses : ""}
                                 onChange={e => setValue(participation, "misses", e)}
                                 className={getInputClass(participation, "misses")}
                                 disabled={loading}
                              />
                           </TableCell>
                           <ToolCell>
                              {participationDataChanged(participation) &&
                                 <>
                                    <LoaderButton
                                       loading={loading}
                                       onClick={() => updateResults(participation)}
                                    >Save</LoaderButton>
                                    <CancelButton
                                       onClick={() => resetParticipation(participation)}
                                       disabled={loading}
                                       className="ml-2">Cancel</CancelButton>
                                 </>
                              }
                           </ToolCell>
                        </tr>
                     )
                  })}
               </TableBody>
            </Table>
         </form>
      ) : (
         <p>{loading ? "Loading ..." : "No participations found"}</p>
      )}
   </>;
}