import classnames from "classnames";

export function H3(props) {
   return (
      <h3 className={classnames("text-2xl", "mb-2", props.className)}>
         {props.children}
      </h3>
   );
}

export function H4(props) {
   return (
      <h3 className={classnames("text-xl", "mb-2", props.className)}>
         {props.children}
      </h3>
   );
}
