import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";

export default function Logout() {
   const { setAuthenticated } = useAppContext();
   const history = useHistory();

   useEffect(() => {
      async function onLoad() {
         try {
            await Auth.signOut();
            setAuthenticated(false);
            history.push("/login");
         } catch (e) {
            console.log(e);
            alert(e.message);
         }
      }

      onLoad();
   });

   return (
      <p>
         Logging out ...
      </p>
   );
}