import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useFormFields } from "../lib/hooksLib";
import { useAppContext } from "../lib/contextLib";
import { validateEmail } from "../lib/utilLib";
import { LoaderButton } from "../components/Buttons";
import { FormGrid, Input } from "../components/Form";

export default function RegistrationConfirmation() {
   const history = useHistory();
   const [loading, setLoading] = useState(false);
   const { setAuthenticated } = useAppContext();
   const [fields, handleFieldChange] = useFormFields({
      email: "",
      password: "",
      code: "",
   });

   async function handleSubmitActivation(e) {
      if (!validateEmail(fields.email)) {
         alert("Email malformed");
         return;
      }

      if (fields.password.length < 1) {
         alert("Password is empty")
         return;
      }

      if (fields.code.length < 1) {
         alert("Activation code is empty")
         return;
      }

      setLoading(true);

      try {
         await Auth.confirmSignUp(fields.email, fields.code);
         await Auth.signIn(fields.email, fields.password);
         setAuthenticated(true);
         history.push("/");
      } catch (e) {
         console.log(e);
         alert(e.message);
         setLoading(false);
      }
   }

   return (
      <FormGrid>
         <label htmlFor="email">E-Mail:</label>
         <Input type="text" idn="email" value={fields.email} onChange={handleFieldChange} />
         <label htmlFor="password">Password:</label>
         <Input type="password" idn="password" value={fields.password} onChange={handleFieldChange} />
         <label htmlFor="code">Code:</label>
         <Input type="text" idn="code" value={fields.code} onChange={handleFieldChange} />
         <div></div>
         <LoaderButton onClick={handleSubmitActivation} loading={loading}>Activate</LoaderButton>
      </FormGrid>
   );
}