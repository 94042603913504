import format from 'date-fns/format';
import { degrees, grayscale, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import QRCode from 'qrcode';
import { map, sortByName, sortByRatedCategory, sortByScore, sortByStartGroup } from './utilLib';

const cardsOnX = 3; // How many cards are on the horizontal axis
const positions = 28; // TODO: Set on event and pass as parameter
const telMedic = "078 706 05 18"; // TODO: Set on event and pass as parameter
const telParcours = "079 367 21 20"; // TODO: Set on event and pass as parameter
const picketColorYellow = {
   de: "Gelb",
   fr: "jaune",
   rgb: rgb(0.8, 0.8, 0),
};
const picketColorBlue = {
   de: "Blau",
   fr: "bleu",
   rgb: rgb(0, 0, 1),
};
const picketColorBlack = {
   de: "Schwarz",
   fr: "noir",
   rgb: rgb(0, 0, 0),
};
const picketColorText = {
   de: "Pflock: ",
   fr: "piquet: "
};
const picketColorMap = {
   A: picketColorYellow,
   V: picketColorYellow,
   S: picketColorYellow,
   YA: picketColorYellow,
   J: picketColorBlue,
   C: picketColorBlack,
};

const functionTexts = {
   captain: {
      de: "Kapitän",
      fr: "Capitaine",
   },
   scribe: {
      de: "Schreiber",
      fr: "Scribe",
   },
};

export async function printScoreCard(grouped, groupNumber, event) {
   const pdf = await PDFDocument.create();

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      header: {
         height: 55,
         lineHeight: 16,
         table: {
            height: 50,
            width: 175,
         },
      },
      card: {
         width: 180,
         height: 360,
         margin: 10,
         padding: 5,
         border: 2,
         lineHeight: 10,
         positions: positions,
         box: {
            header: {
               x: 13,
               y: 5,
            },
            padding: 2,
            lineHeight: 8,
            height: 16,
            width: 17,
            offset: 11,
         }
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
   };

   if (groupNumber) {
      await drawScoreCardGroupPage(doc, grouped[groupNumber], groupNumber);
   } else {
      for (let i = 1; i <= Object.keys(grouped).length; i++) {
         await drawScoreCardGroupPage(doc, grouped[i], i);
      }
   }

   return await doc.pdf.save();
}

async function drawScoreCardGroupPage(doc, group, groupNumber) {
   doc.page = doc.pdf.addPage();

   drawScoreCardPageHeader(doc, groupNumber);

   for (let i = 0; i < group.members.length; i++) {
      await drawScoreCard(doc, group.members[i], i);
   }

   drawScoreCardPageFooter(doc);
}

function drawScoreCardPageHeader(doc, groupNumber) {
   const { height } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.drawText(doc.event.name + "  " + format(new Date(doc.event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
      y: height - doc.margin.top - doc.header.lineHeight,
   });
   doc.page.drawText("Scorecard Gruppe / groupe " + groupNumber, {
      x: doc.margin.left,
      y: height - doc.margin.top - doc.header.lineHeight * 2,
   });
   doc.page.setFontSize(doc.card.lineHeight);
   doc.page.drawText("Sanität / médecin: " + telMedic + ", Parcours: " + telParcours, {
      x: doc.margin.left,
      y: height - doc.margin.top - doc.header.lineHeight * 3 + 2,
   });

   drawAnimalSchema(doc);
   drawValueTable(doc);
}

function drawAnimalSchema(doc) {
   const x = doc.page.getSize().width - doc.margin.right - doc.header.table.width - 100;
   const y = doc.page.getSize().height - doc.margin.top + 42;

   // Animal
   doc.page.drawSvgPath(
      "m 93,83 -6,18 5,11 -9,0.096 -6,-15 -22,4 -11,-4 -7,6 4,9 -7,1 -6,-14 -1,-16 7,-17 25,-6 28,3 11,-10 16,13 -10,18 z", {
      x: x,
      y: y,
      borderWidth: 1,
      color: grayscale(1),
      scale: 0.8,
   });
   doc.page.drawLine({
      start: { x: x + 66, y: y - 77 },
      end: { x: x + 105, y: y - 81 },
      thickness: 1,
   });

   // Spot
   doc.page.drawEllipse({
      x: x + 55,
      y: y - 65,
      xScale: 15,
      yScale: 10,
      borderWidth: 1,
      color: grayscale(1),
      rotate: degrees(15),
   });
   doc.page.drawLine({
      start: { x: x + 55, y: y - 70 },
      end: { x: x + 105, y: y - 71 },
      thickness: 1,
   });

   // Superspot
   doc.page.drawCircle({
      x: x + 60,
      y: y - 62,
      size: 4,
      borderWidth: 1,
      color: grayscale(1),
   });
   doc.page.drawLine({
      start: { x: x + 60, y: y - 62 },
      end: { x: x + 105, y: y - 61 },
      thickness: 1,
   });
}

function drawValueTable(doc) {
   const x = doc.page.getSize().width - doc.margin.right - doc.header.table.width;
   const y = doc.page.getSize().height - doc.margin.top - doc.card.lineHeight;
   doc.page.moveTo(x, y);
   doc.page.drawText("Wertung Pfeile / comptation flèches", {
      font: doc.fonts.bold,
   });
   doc.page.moveRight(10);
   doc.page.moveDown(doc.card.lineHeight + 2);
   doc.page.drawText("Superspot:");
   doc.page.drawText("10", {
      x: x + 100,
   });
   doc.page.moveDown(doc.card.lineHeight);
   doc.page.drawText("Spot:");
   doc.page.drawText("8", {
      x: x + 105,
   });
   doc.page.moveDown(doc.card.lineHeight);
   doc.page.drawText("Tier / animal:");
   doc.page.drawText("5", {
      x: x + 105,
   });
}

async function drawScoreCard(doc, member, index) {
   doc.card.x = doc.margin.left + index % cardsOnX * (doc.card.width + doc.card.margin);
   const { height } = doc.page.getSize(); // Y starts at bottom of page
   doc.card.y = height - (doc.margin.top + doc.header.height + Math.floor(index / cardsOnX) * (doc.card.height + doc.card.margin));

   doc.page.setFont(doc.fonts.normal);
   doc.page.setFontSize(doc.card.lineHeight);

   // Card border
   doc.page.drawRectangle({
      x: doc.card.x,
      y: doc.card.y,
      width: doc.card.width,
      height: -doc.card.height,
      borderWidth: doc.card.border,
      color: grayscale(1),
   });

   drawScoreCardHeader(doc, member);
   doc.page.moveDown(doc.card.lineHeight);

   doc.page.setFontSize(doc.card.box.lineHeight);

   // Header for score boxes
   drawScoreBoxHeader(doc);
   doc.page.moveDown(doc.card.lineHeight);
   doc.page.moveDown(doc.card.lineHeight);

   // Score boxes for each shooting position
   for (let position = 1; position <= Math.ceil(doc.card.positions / 2); position++) {
      drawScoreCardPositionRow(doc, position);
   }
   doc.page.moveDown(doc.card.lineHeight);

   // Boxes for score totals
   await drawScoreCardTotals(doc, member);
}

function drawScoreCardHeader(doc, member) {
   // Member name
   doc.page.moveTo(doc.card.x + doc.card.padding, doc.card.y - doc.card.padding - doc.card.lineHeight);
   const nameText = fitText(
      member.lastName + " " + member.firstName,
      130,
      doc.card.box.lineHeight,
      doc.fonts.bold
   );
   doc.page.drawText(nameText, { font: doc.fonts.bold });
   doc.page.moveDown(doc.card.lineHeight + 2);

   const fontSize = doc.card.lineHeight - 1;
   doc.page.setFontSize(fontSize);

   // Category
   const ratedCategory = member.ratedAge + member.ratedGender + member.ratedCategory;

   doc.page.drawText(ratedCategory);

   if (member.age !== member.ratedAge || member.gender !== member.ratedGender || member.category !== member.ratedCategory) {
      const ratedCategoryWidth = doc.fonts.normal.widthOfTextAtSize(ratedCategory, fontSize);
      doc.page.drawText("(" + member.age + member.gender + member.category + ")", {
         x: doc.card.x + doc.card.padding + ratedCategoryWidth + 3,
      });
   }

   // Picket color rect
   const picketColorRectOffset = 12;
   doc.page.drawRectangle({
      x: doc.card.x + doc.card.width - picketColorRectOffset,
      width: 8,
      height: 20,
      color: picketColorMap[member.age].rgb,
   });
   // Picket color text
   const picketText = picketColorText[member.language] + picketColorMap[member.age][member.language];
   const picketTextWidth = doc.fonts.normal.widthOfTextAtSize(picketText, fontSize);
   doc.page.drawText(picketText, {
      x: doc.card.x + doc.card.width - picketTextWidth - picketColorRectOffset - 3,
   });

   doc.page.moveDown(doc.card.lineHeight);

   let functionText = "";
   if (member.groupCaptain) {
      functionText += functionTexts.captain[member.language];
   }
   if (member.scribe) {
      if (functionText.length > 0) {
         functionText += ", ";
      }
      functionText += functionTexts.scribe[member.language];
   }
   if (functionText.length > 0) {
      doc.page.drawText(functionText);
   }

}

function drawScoreBoxHeader(doc) {
   const x = doc.page.getX() + doc.card.box.header.x;
   const y = doc.page.getY() - doc.card.box.header.y;
   doc.page.drawText("I", {
      x: x,
      y: y,
   });
   doc.page.drawText("II", {
      x: x + doc.card.box.width,
      y: y,
   });
   doc.page.drawText("I+II", {
      x: x + doc.card.box.width * 2,
      y: y,
   });
   doc.page.drawText("Total", {
      x: x + doc.card.box.width * 3,
      y: y,
   });
   doc.page.drawText("I", {
      x: x + doc.card.width / 2,
      y: y,
   });
   doc.page.drawText("II", {
      x: x + doc.card.box.width + doc.card.width / 2,
      y: y,
   });
   doc.page.drawText("I+II", {
      x: x + doc.card.box.width * 2 + doc.card.width / 2,
      y: y,
   });
   doc.page.drawText("Total", {
      x: x + doc.card.box.width * 3 + doc.card.width / 2,
      y: y,
   });
}

function drawScoreCardPositionRow(doc, position) {
   // Draw position 1 - positions/2 in the left column
   drawScoreCardPositionCells(doc, position);
   const positionCol2 = position + Math.ceil(doc.card.positions / 2);
   if (positionCol2 <= doc.card.positions) {
      // Draw position positions/2 - positions in the right column
      drawScoreCardPositionCells(doc, positionCol2);
   }
   doc.page.moveDown(doc.card.box.height);
}

function drawScoreCardPositionCells(doc, position) {
   let x = doc.card.x + doc.card.box.padding;
   const y = doc.page.getY() - (doc.card.box.height - doc.card.lineHeight) / 2 - 1;
   if (position > Math.ceil(positions / 2)) {
      // Right column
      x += doc.card.width / 2;
   }

   // Position number
   doc.page.drawText(position + "", {
      x: x,
   });

   // Box 1 for shot result 1
   doc.page.drawRectangle({
      x: x + doc.card.box.offset,
      y: y,
      width: doc.card.box.width,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   // Box 2 for shot result 2
   doc.page.drawRectangle({
      x: x + doc.card.box.offset + doc.card.box.width,
      y: y,
      width: doc.card.box.width,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   // Box 3 for sum of shot 1 + 2
   doc.page.drawRectangle({
      x: x + doc.card.box.offset + doc.card.box.width * 2,
      y: y,
      width: doc.card.box.width,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   // Box 4 for running total
   doc.page.drawRectangle({
      x: x + doc.card.box.offset + doc.card.box.width * 3,
      y: y,
      width: doc.card.box.width * 1.5,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
}

async function drawScoreCardTotals(doc, member) {
   const xBox = doc.page.x - doc.card.padding + doc.card.width - doc.card.box.width * 4 - 0.5;
   const yBoxCorrection = (doc.card.box.height - doc.card.lineHeight) / 2;

   doc.page.drawRectangle({
      x: xBox,
      y: doc.page.getY() - yBoxCorrection,
      width: doc.card.box.width * 4,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   const totalText = "Total";
   doc.page.drawText(totalText, { x: xBox - 3 - doc.fonts.normal.widthOfTextAtSize(totalText, doc.card.lineHeight) });
   doc.page.moveDown(doc.card.box.height);

   doc.page.drawRectangle({
      x: xBox,
      y: doc.page.getY() - yBoxCorrection,
      width: doc.card.box.width * 4,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   const spotsText = "Superspots";
   doc.page.drawText(spotsText, { x: xBox + 3 - doc.fonts.normal.widthOfTextAtSize(spotsText, doc.card.lineHeight) });
   doc.page.moveDown(doc.card.box.height);

   doc.page.drawRectangle({
      x: xBox,
      y: doc.page.getY() - yBoxCorrection,
      width: doc.card.box.width * 4,
      height: doc.card.box.height,
      borderWidth: 1,
      color: grayscale(1),
   });
   const nullText = "Nuller / nuls";
   doc.page.drawText(nullText, { x: xBox + 3 - doc.fonts.normal.widthOfTextAtSize(nullText, doc.card.lineHeight) });
   doc.page.moveDown(doc.card.box.height);

   doc.page.drawText("Schütze / tireur:");

   if (member.scribe) {
      const canvas = await QRCode.toCanvas(
         `https://bowhunter.app/scribe/${map(member.participationId)}`,
         { errorCorrectionLevel: "M" }
      );
      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      const qrPng = await doc.pdf.embedPng(await blob.arrayBuffer());
      doc.page.drawImage(qrPng, {
         x: doc.page.x - 4,
         y: doc.page.getY() - 3 + doc.card.lineHeight,
         width: 60,
         height: 60,
      });
   }
}

function drawScoreCardPageFooter(doc) {
   doc.page.drawText("Schreiber / écriteur:", {
      x: doc.margin.left,
      y: doc.margin.bottom,
   });
}

export async function printLeaderBoard(grouped, event, logoBytes) {
   const pdf = await PDFDocument.create();

   const logo = await pdf.embedPng(logoBytes)

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
      header: {
         lineHeight: 16,
      },
      entries: {
         header: {
            lineHeight: 16,
            lineHeightSmall: 10,
            minY: 50,
         },
         lineHeight: 12,
         minY: 22,
      },
      mapping: {
         gender: {
            F: "Damen",
            M: "Herren",
         },
         age: {
            A: "Erwachsene",
            V: "Veteranen",
            S: "Senioren",
            YA: "Junge Erwachsene",
            J: "Junioren",
            C: "Kinder",
         },
         category: {
            "BB-C": "Barebow Compound",
            "BB-R": "Barebow Recurve",
            "BH-C": "Bowhunter Compound",
            "BH-R": "Bowhunter Recurve",
            "BL": "Bowhunter Limited",
            "BU": "Bowhunter Unlimited",
            "FS-C": "Freestyle Limited Compound",
            "FS-R": "Freestyle Limited Recurve",
            "FU": "Freestyle Unlimited",
            "HB": "Historical Bow",
            "LB": "Longbow",
            "TR": "Traditional Recurve",
         },
      },
   };

   addLeaderBoardPage(doc, grouped, event, logo);
   Object.keys(grouped).forEach(group => {
      const participations = grouped[group].participations;
      if (doc.page.getY() < doc.entries.header.minY + participations.length * doc.entries.lineHeight + doc.margin.bottom) {
         addLeaderBoardPage(doc, grouped, event, logo);
      }
      drawLeaderBoardGroupHeader(doc, grouped, group);
      let ranks = [];
      for (let id = 0; id < participations.length; id++) {
         if (id > 0) {
            if (sortByScore(participations[id], participations[id - 1], true) === 0) {
               drawLeaderBoardEntry(doc, participations[id], ranks[id - 1]);
               ranks.push(ranks[id - 1]);
               continue;
            }
         }
         drawLeaderBoardEntry(doc, participations[id], id + 1);
         ranks.push(id + 1);
      }
      doc.page.moveDown(doc.entries.lineHeight);
   });

   addPagesCounters(doc);

   return await doc.pdf.save();
}

function addLeaderBoardPage(doc, grouped, event, logo) {
   doc.page = doc.pdf.addPage();
   doc.page.moveTo(doc.margin.left, doc.page.getSize().height - doc.margin.top);

   const count = Object.keys(grouped).reduce((acc, group) => acc + grouped[group].participations.length, 0);
   drawLeaderBoardHeader(doc, event, logo, count);
}

function drawLeaderBoardHeader(doc, event, logo, count) {
   const { height, width } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText(event.name + "  " + format(new Date(event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
   });
   const organisationText = "Bogenschützen Forst Bern";
   const organisationTextWidth = doc.fonts.normal.widthOfTextAtSize(organisationText, doc.header.lineHeight);
   doc.page.drawText(organisationText, {
      x: width - doc.margin.right - organisationTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText("Rangliste Doppelhunter", {
      x: doc.margin.left,
   });

   doc.page.setFontSize(doc.entries.lineHeight);
   const countText = count + " Personen";
   const countTextWidth = doc.fonts.normal.widthOfTextAtSize(countText, doc.entries.lineHeight);
   doc.page.drawText(countText, {
      x: width - doc.margin.right - countTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight * 2);

   doc.page.drawImage(logo, {
      x: width / 2 - 50,
      y: height - doc.margin.top - 60 + 10,
      width: 100,
      height: 62,
   });

   doc.page.moveDown(10);
}

function drawLeaderBoardGroupHeader(doc, grouped, group) {
   const { ratedAge, ratedGender, ratedCategory } = grouped[group].participations[0];
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.header.lineHeight);

   doc.page.drawRectangle({
      x: doc.margin.left,
      y: doc.page.getY() - doc.entries.header.lineHeight - 3,
      width: doc.page.getSize().width - doc.margin.left - doc.margin.right,
      height: doc.entries.header.lineHeight * 2 + 2,
      color: rgb(0.9, 0.9, 0.9),
   });

   doc.page.drawText(
      ratedAge + ratedGender + ratedCategory +
      " " + doc.mapping.category[ratedCategory] +
      " " + doc.mapping.age[ratedAge] +
      " " + doc.mapping.gender[ratedGender],
      {
         x: doc.margin.left + 3,
      }
   );
   doc.page.moveDown(doc.entries.header.lineHeight);

   doc.page.setFontSize(doc.entries.header.lineHeightSmall);
   doc.page.drawText("Name", { x: doc.margin.left + 20 });
   doc.page.drawText("Club", { x: doc.margin.left + 210 });
   doc.page.drawText("Anmeldung", { x: rightEdge - 153 });
   doc.page.drawText("Spots", { x: rightEdge - 93 });
   doc.page.drawText("Nuller", { x: rightEdge - 63 });
   doc.page.drawText("Punkte", { x: rightEdge - 33 });
   doc.page.moveDown(doc.entries.header.lineHeight);
}

function drawLeaderBoardEntry(doc, participation, rank) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.lineHeight);

   const rankText = rank + "";
   const rankTextWidth = doc.fonts.normal.widthOfTextAtSize(rankText, doc.entries.lineHeight);
   doc.page.drawText(rankText, {
      x: doc.margin.left + 10 - rankTextWidth,
   });
   const nameText = fitText(
      participation.lastName + " " + participation.firstName,
      210 - 20 - 10,
      doc.entries.lineHeight,
      doc.fonts.normal
   );
   doc.page.drawText(nameText, {
      x: doc.margin.left + 20,
   });

   if (participation.club) {
      const clubText = fitText(
         participation.club,
         rightEdge - 153 - doc.margin.left - 210 - 10,
         doc.entries.lineHeight,
         doc.fonts.normal
      );
      doc.page.drawText(clubText, {
         x: doc.margin.left + 210,
      });
   }
   doc.page.drawText(participation.age + participation.gender + participation.category, {
      x: rightEdge - 153,
   });

   const superspotsText = participation.superspots + "";
   const superspotsTextWidth = doc.fonts.normal.widthOfTextAtSize(superspotsText, doc.entries.lineHeight);
   doc.page.drawText(superspotsText, {
      x: rightEdge - 68 - superspotsTextWidth,
   });

   const missesText = participation.misses + "";
   const missesTextWidth = doc.fonts.normal.widthOfTextAtSize(missesText, doc.entries.lineHeight);
   doc.page.drawText(missesText, {
      x: rightEdge - 38 - missesTextWidth,
   });

   const scoreText = participation.score + "";
   const scoreTextWidth = doc.fonts.normal.widthOfTextAtSize(scoreText, doc.entries.lineHeight);
   doc.page.drawText(scoreText, {
      x: rightEdge - 3 - scoreTextWidth,
   });

   doc.page.moveDown(doc.entries.lineHeight);
}

function fitText(text, maxWidth, lineHeight, font) {
   let textWidth = font.widthOfTextAtSize(text, lineHeight);
   while (textWidth >= maxWidth) {
      text = text.substring(0, text.length - 1);
      textWidth = font.widthOfTextAtSize(text, lineHeight);
   }

   return text;
}

export async function printParticipationList(participations, event, logoBytes) {
   const pdf = await PDFDocument.create();

   const logo = await pdf.embedPng(logoBytes)

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
      header: {
         lineHeight: 16,
      },
      entries: {
         header: {
            lineHeight: 16,
            lineHeightSmall: 10,
            minY: 50,
         },
         lineHeight: 12,
         minY: 22,
      },
   };

   const participationsFiltered = participations.filter(p => p.waitList !== true);
   const grouped = participationsFiltered.sort(sortByName).reduce((previous, current) => {
      const letter = current.lastName.substring(0, 1).toUpperCase();
      if (!previous.hasOwnProperty(letter)) {
         previous[letter] = [];
      }
      previous[letter].push(current);
      return previous
   }, {});

   addParticipationListPage(doc, participationsFiltered, event, logo);
   Object.keys(grouped).forEach(letter => {
      const letterHeaderHeight = doc.header.lineHeight + doc.entries.header.lineHeightSmall + 23;
      const entryHeight = doc.entries.lineHeight;
      if (doc.page.getY() < letterHeaderHeight + grouped[letter].length * entryHeight + doc.margin.bottom) {
         addParticipationListPage(doc, participationsFiltered, event, logo);
      }
      drawParticipationListLetterHeader(doc, letter);
      grouped[letter].forEach(participation => drawParticipationListEntry(doc, participation));
   });

   addPagesCounters(doc);

   return await doc.pdf.save();
}

function addParticipationListPage(doc, participations, event, logo) {
   doc.page = doc.pdf.addPage();
   doc.page.moveTo(doc.margin.left, doc.page.getSize().height - doc.margin.top);

   drawParticipationListHeader(doc, event, logo, participations.length);
}

function drawParticipationListHeader(doc, event, logo, count) {
   const { height, width } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText(event.name + "  " + format(new Date(event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
   });
   const organisationText = "Bogenschützen Forst Bern";
   const organisationTextWidth = doc.fonts.normal.widthOfTextAtSize(organisationText, doc.header.lineHeight);
   doc.page.drawText(organisationText, {
      x: width - doc.margin.right - organisationTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText("Teilnehmerliste Alphabetisch", {
      x: doc.margin.left,
   });

   doc.page.setFontSize(doc.entries.lineHeight);
   const countText = count + " Personen";
   const countTextWidth = doc.fonts.normal.widthOfTextAtSize(countText, doc.entries.lineHeight);
   doc.page.drawText(countText, {
      x: width - doc.margin.right - countTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight * 2);

   doc.page.drawImage(logo, {
      x: width / 2 - 50,
      y: height - doc.margin.top - 60 + 10,
      width: 100,
      height: 62,
   });

   doc.page.moveDown(-doc.header.lineHeight);
}

function drawParticipationListLetterHeader(doc, letter) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.header.lineHeight);
   doc.page.moveDown(10);

   doc.page.drawRectangle({
      x: doc.margin.left,
      y: doc.page.getY() - doc.entries.header.lineHeight * 2 - 3,
      width: doc.page.getSize().width - doc.margin.left - doc.margin.right,
      height: doc.entries.header.lineHeight * 2 + 2,
      color: rgb(0.9, 0.9, 0.9),
   });

   doc.page.moveDown(doc.entries.header.lineHeight);
   doc.page.drawText(
      letter,
      {
         x: doc.margin.left + 3,
      }
   );

   doc.page.moveDown(doc.entries.header.lineHeightSmall + 3);
   doc.page.setFontSize(doc.entries.header.lineHeightSmall);
   doc.page.drawText("Name", { x: doc.margin.left + 3 });
   doc.page.drawText("Club", { x: doc.margin.left + 200 });
   doc.page.drawText("Anmeldung", { x: rightEdge - 160 });
   doc.page.drawText("Kategorie", { x: rightEdge - 90 });
   const groupLabel = "Gruppe";
   doc.page.drawText(groupLabel, { x: rightEdge - 3 - doc.fonts.normal.widthOfTextAtSize(groupLabel, doc.entries.header.lineHeightSmall) });
   doc.page.moveDown(10);
}

function drawParticipationListEntry(doc, participation) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.lineHeight);

   doc.page.moveDown(doc.entries.lineHeight)
   const nameText = fitText(
      participation.lastName + " " + participation.firstName,
      210 - 20 - 10,
      doc.entries.lineHeight,
      doc.fonts.normal
   );
   doc.page.drawText(nameText, {
      x: doc.margin.left + 3,
   });

   if (participation.club) {
      const clubText = fitText(
         participation.club,
         rightEdge - 160 - doc.margin.left - 200 - 10,
         doc.entries.lineHeight,
         doc.fonts.normal
      );
      doc.page.drawText(clubText, {
         x: doc.margin.left + 200,
      });
   }

   doc.page.drawText(participation.age + participation.gender + participation.category, {
      x: rightEdge - 160,
   });

   doc.page.drawText(participation.ratedAge + participation.ratedGender + participation.ratedCategory, {
      x: rightEdge - 90,
   });

   const groupText = `${participation.startGroup}`
   doc.page.drawText(groupText, {
      x: rightEdge - 3 - doc.fonts.normal.widthOfTextAtSize(groupText, doc.entries.lineHeight),
   });
}

function addPagesCounters(doc) {
   const pages = doc.pdf.getPages();
   for (const [i, page] of Object.entries(pages)) {
      const text = `${+i + 1} / ${pages.length}`;
      const textWidth = doc.fonts.normal.widthOfTextAtSize(text, doc.entries.lineHeight);
      page.drawText(text, {
         x: page.getWidth() / 2 - textWidth / 2,
         y: doc.margin.bottom - doc.entries.lineHeight,
      });
   }
}

export async function printGroupList(participations, event, logoBytes) {
   const pdf = await PDFDocument.create();

   const logo = await pdf.embedPng(logoBytes)

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
      header: {
         lineHeight: 16,
      },
      entries: {
         header: {
            lineHeight: 16,
            lineHeightSmall: 10,
            minY: 50,
         },
         lineHeight: 12,
         minY: 22,
      },
   };

   const participationsFiltered = participations.filter(p => p.waitList !== true);
   const grouped = participationsFiltered.sort(sortByStartGroup).reduce((previous, current) => {
      const groupNumber = current.startGroup;
      if (!previous.hasOwnProperty(groupNumber)) {
         previous[groupNumber] = [];
      }
      previous[groupNumber].push(current);
      return previous
   }, {});

   addGroupListPage(doc, participationsFiltered, event, logo);
   Object.keys(grouped).forEach(groupNumber => {
      const letterHeaderHeight = doc.header.lineHeight + doc.entries.header.lineHeightSmall + 23;
      const entryHeight = doc.entries.lineHeight;
      if (doc.page.getY() < letterHeaderHeight + grouped[groupNumber].length * entryHeight + doc.margin.bottom) {
         addGroupListPage(doc, participationsFiltered, event, logo);
      }
      drawGroupListGroupHeader(doc, groupNumber);
      grouped[groupNumber].forEach(participation => drawGroupListEntry(doc, participation));
   });

   addPagesCounters(doc);

   return await doc.pdf.save();
}

function addGroupListPage(doc, participations, event, logo) {
   doc.page = doc.pdf.addPage();
   doc.page.moveTo(doc.margin.left, doc.page.getSize().height - doc.margin.top);

   drawGroupListHeader(doc, event, logo, participations.length);
}

function drawGroupListHeader(doc, event, logo, count) {
   const { height, width } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText(event.name + "  " + format(new Date(event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
   });
   const organisationText = "Bogenschützen Forst Bern";
   const organisationTextWidth = doc.fonts.normal.widthOfTextAtSize(organisationText, doc.header.lineHeight);
   doc.page.drawText(organisationText, {
      x: width - doc.margin.right - organisationTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText("Gruppenliste", {
      x: doc.margin.left,
   });

   doc.page.setFontSize(doc.entries.lineHeight);
   const countText = count + " Personen";
   const countTextWidth = doc.fonts.normal.widthOfTextAtSize(countText, doc.entries.lineHeight);
   doc.page.drawText(countText, {
      x: width - doc.margin.right - countTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight * 2);

   doc.page.drawImage(logo, {
      x: width / 2 - 50,
      y: height - doc.margin.top - 60 + 10,
      width: 100,
      height: 62,
   });

   doc.page.moveDown(-doc.header.lineHeight);
}

function drawGroupListGroupHeader(doc, groupNumber) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.header.lineHeight);
   doc.page.moveDown(10);

   doc.page.drawRectangle({
      x: doc.margin.left,
      y: doc.page.getY() - doc.entries.header.lineHeight * 2 - 3,
      width: doc.page.getSize().width - doc.margin.left - doc.margin.right,
      height: doc.entries.header.lineHeight * 2 + 2,
      color: rgb(0.9, 0.9, 0.9),
   });

   doc.page.moveDown(doc.entries.header.lineHeight);
   doc.page.drawText(
      "Gruppe " + groupNumber,
      {
         x: doc.margin.left + 3,
      }
   );

   doc.page.moveDown(doc.entries.header.lineHeightSmall + 3);
   doc.page.setFontSize(doc.entries.header.lineHeightSmall);
   doc.page.drawText("Name", { x: doc.margin.left + 3 });
   doc.page.drawText("Club", { x: doc.margin.left + 200 });
   doc.page.drawText("Kategorie", { x: rightEdge - 140 });
   doc.page.drawText("Funktion", { x: rightEdge - 70 });
   doc.page.moveDown(10);
}

function drawGroupListEntry(doc, participation) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.lineHeight);

   doc.page.moveDown(doc.entries.lineHeight)
   const nameText = fitText(
      participation.lastName + " " + participation.firstName,
      210 - 20 - 10,
      doc.entries.lineHeight,
      doc.fonts.normal
   );
   doc.page.drawText(nameText, {
      x: doc.margin.left + 3,
   });

   if (participation.club) {
      const clubText = fitText(
         participation.club,
         rightEdge - 160 - doc.margin.left - 200 - 10,
         doc.entries.lineHeight,
         doc.fonts.normal
      );
      doc.page.drawText(clubText, {
         x: doc.margin.left + 200,
      });
   }

   doc.page.drawText(participation.ratedAge + participation.ratedGender + participation.ratedCategory, {
      x: rightEdge - 140,
   });

   let groupFunction
   if (participation.groupCaptain) {
      groupFunction = "Captain"
   }
   if (participation.scribe) {
      groupFunction = "Schreiber"
   }
   if (groupFunction) {
      doc.page.drawText(groupFunction, {
         x: rightEdge - 70,
      });
   }
}

export async function printCategoryList(participations, event, logoBytes) {
   const pdf = await PDFDocument.create();

   const logo = await pdf.embedPng(logoBytes)

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
      header: {
         lineHeight: 16,
      },
      entries: {
         header: {
            lineHeight: 16,
            lineHeightSmall: 10,
            minY: 50,
         },
         lineHeight: 12,
         minY: 22,
      },
      mapping: {
         gender: {
            F: "Damen",
            M: "Herren",
         },
         age: {
            A: "Erwachsene",
            V: "Veteranen",
            S: "Senioren",
            YA: "Junge Erwachsene",
            J: "Junioren",
            C: "Kinder",
         },
         category: {
            "BB-C": "Barebow Compound",
            "BB-R": "Barebow Recurve",
            "BH-C": "Bowhunter Compound",
            "BH-R": "Bowhunter Recurve",
            "BL": "Bowhunter Limited",
            "BU": "Bowhunter Unlimited",
            "FS-C": "Freestyle Limited Compound",
            "FS-R": "Freestyle Limited Recurve",
            "FU": "Freestyle Unlimited",
            "HB": "Historical Bow",
            "LB": "Longbow",
            "TR": "Traditional Recurve",
         },
      },
   };

   const participationsFiltered = participations.filter(p => p.waitList !== true);
   const grouped = participationsFiltered.sort(sortByRatedCategory).reduce((previous, current) => {
      const category = current.ratedAge + current.ratedGender + current.ratedCategory
         + " " + doc.mapping.age[current.ratedAge]
         + " " + doc.mapping.gender[current.ratedGender]
         + " " + doc.mapping.category[current.ratedCategory];
      if (!previous.hasOwnProperty(category)) {
         previous[category] = [];
      }
      previous[category].push(current);
      return previous;
   }, {});
   const categoryCount = Object.keys(grouped).length;

   addCategoryListPage(doc, participationsFiltered, event, logo, categoryCount);
   Object.keys(grouped).forEach(category => {
      const letterHeaderHeight = doc.header.lineHeight + doc.entries.header.lineHeightSmall + 23;
      const entryHeight = doc.entries.lineHeight;
      if (doc.page.getY() < letterHeaderHeight + grouped[category].length * entryHeight + doc.margin.bottom) {
         addCategoryListPage(doc, participationsFiltered, event, logo, categoryCount);
      }
      drawCategoryListCategoryHeader(doc, category);
      grouped[category].forEach(participation => drawCategoryListEntry(doc, participation));
   });

   addPagesCounters(doc);

   return await doc.pdf.save();
}

function addCategoryListPage(doc, participations, event, logo, categoryCount) {
   doc.page = doc.pdf.addPage();
   doc.page.moveTo(doc.margin.left, doc.page.getSize().height - doc.margin.top);

   drawCategoryListHeader(doc, event, logo, participations.length, categoryCount);

}

function drawCategoryListHeader(doc, event, logo, count, categoryCount) {
   const { height, width } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText(event.name + "  " + format(new Date(event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
   });
   const organisationText = "Bogenschützen Forst Bern";
   const organisationTextWidth = doc.fonts.normal.widthOfTextAtSize(organisationText, doc.header.lineHeight);
   doc.page.drawText(organisationText, {
      x: width - doc.margin.right - organisationTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText("Teilnehmerliste nach Kategorie", {
      x: doc.margin.left,
   });

   doc.page.setFontSize(doc.entries.lineHeight);
   const categoryText = categoryCount + " Kategorien";
   const categoryTextWidth = doc.fonts.normal.widthOfTextAtSize(categoryText, doc.entries.lineHeight);
   doc.page.drawText(categoryText, {
      x: width - doc.margin.right - categoryTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);

   doc.page.setFontSize(doc.entries.lineHeight);
   const countText = count + " Personen";
   const countTextWidth = doc.fonts.normal.widthOfTextAtSize(countText, doc.entries.lineHeight);
   doc.page.drawText(countText, {
      x: width - doc.margin.right - countTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);

   doc.page.drawImage(logo, {
      x: width / 2 - 50,
      y: height - doc.margin.top - 60 + 10,
      width: 100,
      height: 62,
   });

   doc.page.moveDown(-doc.header.lineHeight);
}

function drawCategoryListCategoryHeader(doc, category) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.header.lineHeight);
   doc.page.moveDown(10);

   doc.page.drawRectangle({
      x: doc.margin.left,
      y: doc.page.getY() - doc.entries.header.lineHeight * 2 - 3,
      width: doc.page.getSize().width - doc.margin.left - doc.margin.right,
      height: doc.entries.header.lineHeight * 2 + 2,
      color: rgb(0.9, 0.9, 0.9),
   });

   doc.page.moveDown(doc.entries.header.lineHeight);
   doc.page.drawText(
      category,
      {
         x: doc.margin.left + 3,
      }
   );

   doc.page.moveDown(doc.entries.header.lineHeightSmall + 3);
   doc.page.setFontSize(doc.entries.header.lineHeightSmall);
   doc.page.drawText("Name", { x: doc.margin.left + 3 });
   doc.page.drawText("Club", { x: doc.margin.left + 200 });
   const groupLabel = "Gruppe";
   doc.page.drawText(groupLabel, { x: rightEdge - 3 - doc.fonts.normal.widthOfTextAtSize(groupLabel, doc.entries.header.lineHeightSmall) });
   doc.page.moveDown(10);
}

function drawCategoryListEntry(doc, participation) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.lineHeight);

   doc.page.moveDown(doc.entries.lineHeight)
   const nameText = fitText(
      participation.lastName + " " + participation.firstName,
      210 - 20 - 10,
      doc.entries.lineHeight,
      doc.fonts.normal
   );
   doc.page.drawText(nameText, {
      x: doc.margin.left + 3,
   });

   if (participation.club) {
      const clubText = fitText(
         participation.club,
         rightEdge - 60 - doc.margin.left - 200 - 10,
         doc.entries.lineHeight,
         doc.fonts.normal
      );
      doc.page.drawText(clubText, {
         x: doc.margin.left + 200,
      });
   }

   const groupText = `${participation.startGroup}`
   doc.page.drawText(groupText, {
      x: rightEdge - 3 - doc.fonts.normal.widthOfTextAtSize(groupText, doc.entries.lineHeight),
   });
}

export async function printRatedCategories(participations, event, logoBytes) {
   const pdf = await PDFDocument.create();

   const logo = await pdf.embedPng(logoBytes)

   const font = await pdf.embedFont(StandardFonts.Helvetica);
   const fontBold = await pdf.embedFont(StandardFonts.HelveticaBold);

   const doc = {
      event: event,
      pdf: pdf,
      fonts: {
         normal: font,
         bold: fontBold,
      },
      margin: {
         top: 20,
         left: 15,
         right: 15,
         bottom: 20,
      },
      header: {
         lineHeight: 16,
      },
      entries: {
         header: {
            lineHeight: 16,
            lineHeightSmall: 10,
            minY: 50,
         },
         lineHeight: 12,
         minY: 22,
      },
      mapping: {
         gender: {
            F: "Damen",
            M: "Herren",
         },
         age: {
            A: "Erwachsene",
            V: "Veteranen",
            S: "Senioren",
            YA: "Junge Erwachsene",
            J: "Junioren",
            C: "Kinder",
         },
         category: {
            "BB-C": "Barebow Compound",
            "BB-R": "Barebow Recurve",
            "BH-C": "Bowhunter Compound",
            "BH-R": "Bowhunter Recurve",
            "BL": "Bowhunter Limited",
            "BU": "Bowhunter Unlimited",
            "FS-C": "Freestyle Limited Compound",
            "FS-R": "Freestyle Limited Recurve",
            "FU": "Freestyle Unlimited",
            "HB": "Historical Bow",
            "LB": "Longbow",
            "TR": "Traditional Recurve",
         },
      },
   };

   const participationsFiltered = participations.filter(p => p.waitList !== true);
   // TODO: Sort differently?
   const grouped = participationsFiltered.sort(sortByRatedCategory).reduce((previous, current) => {
      const category = current.ratedAge + current.ratedGender + current.ratedCategory
         + " " + doc.mapping.age[current.ratedAge]
         + " " + doc.mapping.gender[current.ratedGender]
         + " " + doc.mapping.category[current.ratedCategory];
      if (!previous.hasOwnProperty(category)) {
         previous[category] = [];
      }
      previous[category].push(current);
      return previous;
   }, {});

   addRatedCategoriesPage(doc, grouped, event, logo, participationsFiltered.length);
   Object.keys(grouped).forEach(category => {
      const sectionHeight = doc.entries.header.lineHeight + doc.entries.header.lineHeightSmall + 13
      if (doc.page.getY() < sectionHeight + 3 + doc.margin.bottom) {
         addRatedCategoriesPage(doc, grouped, event, logo, participationsFiltered.length);
      }
      drawRatedCategory(doc, category, grouped[category]);
   });

   addPagesCounters(doc);

   return await doc.pdf.save();
}

function addRatedCategoriesPage(doc, categories, event, logo, participationsCount) {
   doc.page = doc.pdf.addPage();
   doc.page.moveTo(doc.margin.left, doc.page.getSize().height - doc.margin.top);

   drawRatedCategoriesHeader(doc, event, logo, Object.keys(categories).length, participationsCount);
}

function drawRatedCategoriesHeader(doc, event, logo, count, participationsCount) {
   const { height, width } = doc.page.getSize(); // Y starts at bottom of page
   doc.page.setFontSize(doc.header.lineHeight);
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText(event.name + "  " + format(new Date(event.eventDate), "dd.MM.yyyy"), {
      x: doc.margin.left,
   });
   const organisationText = "Bogenschützen Forst Bern";
   const organisationTextWidth = doc.fonts.normal.widthOfTextAtSize(organisationText, doc.header.lineHeight);
   doc.page.drawText(organisationText, {
      x: width - doc.margin.right - organisationTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);
   doc.page.drawText("Wertungskategorien", {
      x: doc.margin.left,
   });

   doc.page.setFontSize(doc.entries.lineHeight);
   const countText = count + " Kategorien";
   const countTextWidth = doc.fonts.normal.widthOfTextAtSize(countText, doc.entries.lineHeight);
   doc.page.drawText(countText, {
      x: width - doc.margin.right - countTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);

   doc.page.setFontSize(doc.entries.lineHeight);
   const participationsText = participationsCount + " Personen";
   const participationsTextWidth = doc.fonts.normal.widthOfTextAtSize(participationsText, doc.entries.lineHeight);
   doc.page.drawText(participationsText, {
      x: width - doc.margin.right - participationsTextWidth,
   });
   doc.page.moveDown(doc.header.lineHeight);

   doc.page.drawImage(logo, {
      x: width / 2 - 50,
      y: height - doc.margin.top - 60 + 10,
      width: 100,
      height: 62,
   });
}

function drawRatedCategory(doc, category, grouped) {
   const rightEdge = doc.page.getSize().width - doc.margin.right;

   doc.page.setFontSize(doc.entries.header.lineHeight);
   doc.page.moveDown(10);

   doc.page.drawRectangle({
      x: doc.margin.left,
      y: doc.page.getY() - doc.entries.header.lineHeight * 2 - 3,
      width: doc.page.getSize().width - doc.margin.left - doc.margin.right,
      height: doc.entries.header.lineHeight * 2 + 2,
      color: rgb(0.9, 0.9, 0.9),
   });

   doc.page.moveDown(doc.entries.header.lineHeight);
   const split = category.split(" ");
   const abbreviated = split[0];
   const longText = split.filter((value, index) => index > 0).join(" ");
   doc.page.drawText(abbreviated, {
      x: doc.margin.left + 3,
   });
   doc.page.drawText(longText, {
      x: doc.margin.left + 3 + 90,
   });

   doc.page.drawText("Teilnehmer:", { x: rightEdge - doc.margin.right - 95 });
   const count = `${grouped.length}`;
   doc.page.drawText(count, {
      x: rightEdge - 3 - doc.fonts.normal.widthOfTextAtSize(count, doc.header.lineHeight),
   });

   doc.page.moveDown(doc.entries.header.lineHeightSmall + 3);
   doc.page.setFontSize(doc.entries.header.lineHeightSmall);

   const redistributed = grouped.reduce((previous, current) => {
      const currentCategory = current.age + current.gender + current.category;
      if (currentCategory === abbreviated) {
         return previous;
      }
      if (!previous.hasOwnProperty(currentCategory)) {
         previous[currentCategory] = 0;
      }
      previous[currentCategory]++;
      return previous;
   }, {});

   if (Object.keys(redistributed).length > 0) {
      const redistributedText = "Umgeteilte Kategorien: " + Object.keys(redistributed).map(redisCat => {
         return `${redistributed[redisCat]} x ${redisCat}`;
      }).join(", ");
      doc.page.drawText(redistributedText, { x: doc.margin.left + 3 + 90 });
   }
}
