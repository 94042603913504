import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Score } from "../components/Score";
import { unmap } from "../lib/utilLib";

export default function Scribe() {
   const [loading, setLoading] = useState(true);
   const [notFound, setNotFound] = useState(false);
   const [score, setScore] = useState({})

   let participationId = ""
   const urlSegments = window.location.href.split("/scribe/");
   if (urlSegments.length > 1) {
      participationId = unmap(urlSegments[1])
   }

   if (!participationId) {
      setNotFound(true);
   }

   useEffect(() => {
      async function onLoad() {
         setLoading(true);
         const score = JSON.parse(localStorage.getItem("score"));
         if (score && score.scribe.participationId === participationId) {
            setScore(score);
         } else {
            try {
               const result = await API.get("main", `/public/events/${participationId}/participations`);
               if (!result || !result.scribe || !result.squad) {
                  setNotFound(true);
               }
               const initialized = initResult(result);
               setScore({
                  state: "start",
                  ...initialized,
               });
            } catch (e) {
               console.log(e)
            }
         }
         setLoading(false)
      }

      onLoad();
   }, [participationId]);

   function initResult(result) {
      const squad = result.squad;
      Object.keys(squad).map(key => {
         squad[key].results = {};
         const picketResult = { 0: "", 1: "" };
         for (let i = 0; i < 28; i++) {
            squad[key].results[i] = picketResult;
         }
         return true;
      });
      return {
         ...result,
         squad,
      }
   }

   return <div className="m-2">
      {loading
         ? "Loading..."
         : <>{notFound
            ? "ERROR: scribe with provided id not found!"
            : <Score score={score} />
         }</>
      }
   </div>
}