import React, { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";

import '../leaflet.css';

export default function GpsTest() {
   const [position, setPosition] = useState([46.94693, 7.43791]);
   const [error, setError] = useState(false);

   function LocationMarker() {
      const map = useMapEvents({
         click() {
            if (!navigator.geolocation) {
               alert("No access to geolocation API on this device or browser");
            } else {
               navigator.geolocation.watchPosition(pos => {
                  console.log(pos);
                  setPosition([pos.coords.latitude, pos.coords.longitude]);
                  map.flyTo([pos.coords.latitude, pos.coords.longitude]);
               }, err => {
                  const error = 'ERROR(' + err.code + '): ' + err.message;
                  console.warn(error);
                  setError(error);
               }, {
                  maximumAge: 10000,
                  enableHighAccuracy: true,
                  timeout: 20000,
               });
            }
         },
      });

      return position === null ? null : (
         <Marker position={position}>
            <Popup>GPS coordinates</Popup>
         </Marker>
      )
   }

   return (
      <div>
         <div>
            {position[0]} {position[1]}
         </div>
         {error &&
            <div>{error}</div>
         }
         <MapContainer className="h-[600px]" center={position} zoom={20} scrollWheelZoom={false}>
            <TileLayer
               url="https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey={apikey}"
               attribution='&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
               apikey="5f34d02d01bc4403b445c31f416f4770"
               maxZoom="22"
            />
            <LocationMarker />
         </MapContainer>
      </div>
   );
}