import { API } from "aws-amplify";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../lib/hooksLib";
import { FormGrid, Input } from "../components/Form";
import { LoaderButton } from "../components/Buttons";

export default function Event() {
   const [loading, setLoading] = useState(false);
   const history = useHistory();
   const [fields, handleFieldChange] = useFormFields({
      name: "",
   });

   async function handleSubmit(event) {
      event.preventDefault();

      setLoading(true);

      try {
         await API.post("main", "/organisations", {
            body: {
               name: fields.name,
            }
         });
         history.push("/");
      } catch (error) {
         console.log(error);
         alert(error.message);
         setLoading(false);
      }
   }

   return (
      <FormGrid>
         <label htmlFor="name">Name:</label>
         <Input type="text" idn="name" value={fields.name} onChange={handleFieldChange} />
         <div></div>
         <LoaderButton onClick={handleSubmit} loading={loading}>Create</LoaderButton>
      </FormGrid>
   );
}