import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CancelButton } from "../components/Buttons";
import ExpanderBox from "../components/ExpanderBox";
import { Input, Select } from "../components/Form";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, ToolCell } from "../components/Table";
import { H3 } from "../components/Typography";
import { filterParticipations, sortByName, sortByPaid, sortByPresent } from "../lib/utilLib";

export default function Reception() {
    const { eventId } = useParams();
    const [participations, setParticipations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [sort, setSort] = useState("name");
    const [filter, setFilter] = useState("");

    useEffect(() => {
        async function onLoad() {
            try {
                const result = await API.get("main", "/events/" + eventId + "/participations");
                setParticipations(result.sort(sortByName));
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        onLoad();
    }, [eventId]);

    async function changePaid(e, participation) {
        setUpdating(true);

        const oldPaid = participation.paid;
        participation.paid = e.target.value;
        try {
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    paid: e.target.value,
                },
            });
            sortParticipations({ target: { value: sort } });
        } catch (error) {
            participation.paid = oldPaid;
            console.log(error);
            alert(error.message);
        } finally {
            setUpdating(false);
        }
    }

    async function changePresent(e, participation) {
        setUpdating(true);

        const oldPresent = participation.present;
        participation.present = e.target.value;
        try {
            await API.put("main", "/participations", {
                body: {
                    ...participation,
                    present: e.target.value,
                },
            });
            sortParticipations({ target: { value: sort } });
        } catch (error) {
            participation.present = oldPresent;
            console.log(error);
            alert(error.message);
        } finally {
            setUpdating(false);
        }
    }

    function sortParticipations(e) {
        switch (e.target.value) {
            default:
            case "name":
                setParticipations(participations.sort(sortByName));
                break;
            case "paid":
                setParticipations(participations.sort(sortByPaid));
                break;
            case "present":
                setParticipations(participations.sort(sortByPresent));
                break;
        }
        setSort(e.target.value);
    }

    return <>
        {participations.length > 0 ? (
            <form>
                <H3>Reception</H3>
                <div class="mb-2">
                    <label htmlFor="sort">Sort:</label>
                    <Select idn="sort" value={sort} onChange={sortParticipations} className="ml-2">
                        <option value="name">Name</option>
                        <option value="paid">Paid</option>
                        <option value="present">Present</option>
                    </Select>
                    <label htmlFor="filter" className="ml-5">Filter:</label>
                    <Input idn="filter" value={filter} onChange={e => setFilter(e.target.value)} className="ml-2" />
                    <CancelButton onClick={() => setFilter("")} className="ml-2">Clear</CancelButton>
                </div>
                <Table>
                    <TableHead>
                        <TableHeadCell title="Anmeldungsnummer">
                            #
                        </TableHeadCell>
                        <TableHeadCell>Name</TableHeadCell>
                        <TableHeadCell>Vorname</TableHeadCell>
                        <TableHeadCell>Group</TableHeadCell>
                        <TableHeadCell>Function</TableHeadCell>
                        <TableHeadCell>
                            Paid {participations.filter(p => p.paid === "Y").length}/{participations.length}
                        </TableHeadCell>
                        <TableHeadCell>
                            Present {participations.filter(p => p.present === "Y").length}/{participations.length}
                        </TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {participations.filter(participation => filterParticipations(participation, filter)).map((participation, key) => {
                            return (
                                <tr
                                    key={participation.participationId}
                                    className={key % 2 ? "bg-gray-100" : ""}
                                >
                                    <TableCell>{participation.participationId}</TableCell>
                                    <TableCell title={participation.lastName}>
                                        <ExpanderBox maxWidth="max-w-4xs">{participation.lastName}</ExpanderBox>
                                    </TableCell>
                                    <TableCell title={participation.firstName}>
                                        <ExpanderBox maxWidth="max-w-4xs">{participation.firstName}</ExpanderBox>
                                    </TableCell>
                                    <TableCell>{participation.startGroup}</TableCell>
                                    <TableCell>
                                        {participation.groupCaptain && "Kapitän"}
                                        {participation.scribe && "Schreiber"}
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            idn={"paid-" + key}
                                            value={participation.paid}
                                            disabled={updating}
                                            onChange={(e) => changePaid(e, participation)}>
                                            <option value="N">N</option>
                                            <option value="Y">Y</option>
                                        </Select>
                                    </TableCell>
                                    <ToolCell>
                                        <Select
                                            idn={"present-" + key}
                                            value={participation.present || "N"}
                                            disabled={updating}
                                            onChange={(e) => changePresent(e, participation)}>
                                            <option value="N">N</option>
                                            <option value="Y">Y</option>
                                        </Select>
                                    </ToolCell>
                                </tr>
                            )
                        })}
                    </TableBody>
                </Table>
            </form>
        ) : (
            <p>{loading ? "Loading ..." : "No participations found"}</p>
        )}
    </>;
}