import classnames from "classnames";

export function FormGrid(props) {
   return (
      <form>
         <div className={classnames("grid grid-cols-1 md:grid-cols-2 max-w-md gap-1", props.className)}>
            {props.children}
         </div>
      </form>
   );
}

export function Input(props) {
   return (
      <input
         type={props.type ? props.type : "text"}
         name={props.idn ? props.idn : props.name}
         id={props.idn ? props.idn : props.id}
         value={props.value}
         onChange={props.onChange}
         list={props.list}
         className={classnames("shadow-md px-2 py-1", props.className, props.disabled ? "opacity-50" : "")} />
   );
}

export function Checkbox(props) {
   return (
      <input
         type="checkbox"
         name={props.idn ? props.idn : props.name}
         id={props.idn ? props.idn : props.id}
         checked={props.checked}
         value={props.value}
         onChange={props.onChange}
         className={classnames("shadow-md", props.className, props.disabled ? "opacity-50" : "")} />
   );
}

export function Select(props) {
   return (
      <select
         id={props.idn ? props.idn : props.id}
         name={props.idn ? props.idn : props.name}
         value={props.value}
         onChange={props.onChange}
         disabled={props.disabled}
         className={classnames("shadow-md pl-2 pr-8 py-1", props.className, props.disabled ? "opacity-50" : "")}>
         {props.children}
      </select>
   );
}

export function TextArea(props) {
   return (
      <textarea
         id={props.idn ? props.idn : props.id}
         name={props.idn ? props.idn : props.name}
         value={props.value}
         onChange={props.onChange}
         className={classnames("shadow-md", props.className)} />
   );
}

export function SelectAge(props) {
   return <Select value={props.value} onChange={props.onChange} {...props}>
      <option value="A">A</option>
      <option value="V">V</option>
      <option value="S">S</option>
      <option value="YA">YA</option>
      <option value="J">J</option>
      <option value="C">C</option>
   </Select>;
}

export function SelectGender(props) {
   return <Select value={props.value} onChange={props.onChange} {...props}>
      <option value="F">F</option>
      <option value="M">M</option>
   </Select>;
}

export function SelectCategory(props) {
   return <Select value={props.value} onChange={props.onChange} {...props}>
      <option value="BH-R">BH-R</option>
      <option value="BH-C">BH-C</option>
      <option value="BU">BU</option>
      <option value="BH-L">BH-L</option>
      <option value="LB">LB</option>
      <option value="TR">TR</option>
      <option value="HB">HB</option>
      <option value="BB-R">BB-R</option>
      <option value="BB-C">BB-C</option>
      <option value="FU">FU</option>
      <option value="FS-R">FS-R</option>
      <option value="FS-C">FS-C</option>
   </Select>;
}
