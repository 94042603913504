import { PrinterIcon } from "@heroicons/react/solid";
import { API, Storage } from "aws-amplify";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CSVButton, CancelButton, DeleteButton, EditButton, IconButton, LoaderButton } from "../components/Buttons";
import ExpanderBox from "../components/ExpanderBox";
import { FormGrid, Input, Select, SelectAge, SelectCategory, SelectGender, TextArea } from "../components/Form";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, ToolCell } from "../components/Table";
import { H3 } from "../components/Typography";
import { useFormFields } from "../lib/hooksLib";
import { printParticipationList } from "../lib/pdfLib";
import { sortByBirthday, sortByBowCategory, sortByClub, sortByDate, sortById, sortByName, sortByPaid, sortByWaitList, validateEmail } from "../lib/utilLib";

export default function ParticipationList() {
   const { eventId } = useParams();
   const [event, setEvent] = useState();
   const [participations, setParticipations] = useState([]);
   const [csv, setCsv] = useState([]);
   const [loading, setLoading] = useState(true);
   const [editParticipation, setEditParticipation] = useState(false);
   const [sort, setSort] = useState("name");
   const [fields, handleFieldChange] = useFormFields({
      lastName: false,
      firstName: false,
      email: false,
      language: false,
      age: false,
      gender: false,
      category: false,
      club: false,
      group: false,
      notes: false,
      waitList: false,
   });
   const [updatingPaid, setUpdatingPaid] = useState(false);

   useEffect(() => {
      async function onLoad() {
         try {
            const event = await API.get("main", "/events/" + eventId);
            setEvent(event);
            const result = await API.get("main", "/events/" + eventId + "/participations?waitlist=1");
            setParticipations(result.sort(sortByName));
            let csvList = result.map(p => {
               return {
                  created: format(new Date(p.createdAt), "yyyy-MM-dd HH:mm:ss"),
                  participationId: p.participationId,
                  paid: p.paid,
                  lastName: p.lastName,
                  firstName: p.firstName,
                  email: p.email,
                  language: p.language,
                  birthday: p.birthday,
                  age: p.age,
                  gender: p.gender,
                  category: p.category,
                  ratedAge: p.ratedAge,
                  ratedGender: p.ratedGender,
                  ratedCategory: p.ratedCategory,
                  club: p.club,
                  group: p.group,
                  startGroup: p.startGroup,
                  faasId: p.faasId,
                  faasCaptain: p.faasCaptain,
                  notes: p.notes,
                  waitList: p.waitList,
               }
            });
            setCsv(csvList.sort((left, right) => {
               if (left.created <= right.created) {
                  return -1;
               }
               return 1;
            }));
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false);
         }
      }

      onLoad();
   }, [eventId]);

   function sortParticipations(e) {
      switch (e.target.value) {
         default:
         case "name":
            setParticipations(participations.sort(sortByName));
            break;
         case "category":
            setParticipations(participations.sort(sortByBowCategory));
            break;
         case "date":
            setParticipations(participations.sort(sortByDate));
            break;
         case "paid":
            setParticipations(participations.sort(sortByPaid));
            break;
         case "birthday":
            setParticipations(participations.sort(sortByBirthday));
            break;
         case "id":
            setParticipations(participations.sort(sortById));
            break;
         case "club":
            setParticipations(participations.sort(sortByClub));
            break;
         case "waitlist":
            setParticipations(participations.sort(sortByWaitList));
            break;
      }
      setSort(e.target.value);
   }


   async function handleSubmit(e) {
      try {
         if (!validateForm()) {
            return;
         }
         setLoading(true);
         updateParticipationData(editParticipation);
         await API.put("main", "/participations", {
            body: editParticipation,
         });
         clearEditForm();
         setEditParticipation(false);
      } catch (e) {
         console.log(e);
         alert(e.message);
         clearEditForm();
         setEditParticipation(false);
      } finally {
         setLoading(false);
      }
   }

   function cancelSubmit() {
      clearEditForm();
      setEditParticipation(false);
   }

   function clearEditForm() {
      for (const [key] of Object.entries(fields)) {
         fields[key] = false;
      }
   }

   function validateForm() {
      if (fields.firstName === "") {
         alert("Vorname darf nicht leer sein");
         return false;
      }
      if (fields.lastName === "") {
         alert("Nachname darf nicht leer sein");
         return false;
      }
      if (fields.lastName === "") {
         alert("Nachname darf nicht leer sein");
         return false;
      }
      if (fields.email === "") {
         alert("Email darf nicht leer sein");
         return false;
      }
      if (fields.email !== false && !validateEmail(fields.email)) {
         alert("Email ungültig");
         return false;
      }
      return true;
   }

   function updateParticipationData(participation) {
      for (const [key] of Object.entries(participation)) {
         participation[key] = getValue(editParticipation, key);
      }
   }

   function getValue(participation, fieldName) {
      if (fields.hasOwnProperty(fieldName) && fields[fieldName] !== false) {
         if (fields[fieldName] === "false") {
            return false;
         }
         return fields[fieldName];
      }
      return participation[fieldName];
   }

   async function deleteParticipation(participation) {
      if (window.confirm("Anmeldung " + participation.participationId + " von "
         + participation.lastName + " "
         + participation.firstName + " wirklich löschen?")) {
         try {
            await API.del("main", "/participations/" + participation.participationId);
            setParticipations(participations.filter(p => { return p.participationId !== participation.participationId }));
         } catch (e) {
            console.log(e);
            alert(e.message);
         }
      }
   }

   function changePaid(e, participation) {
      setParticipations(participations.map(p => {
         if (p.participationId === participation.participationId) {
            p.paid = e.target.value;
         }
         return p;
      }));
      updatePaid(participation);
   }

   async function updatePaid(participation) {
      setUpdatingPaid(true);
      try {
         await API.put("main", "/participations", {
            body: participation
         });
      } catch (e) {
         console.log(e);
         alert(e.message);
      } finally {
         setUpdatingPaid(false);
      }
   }

   async function printPdf(participations) {
      try {
         //const logoUrl = "organisations/" + event.eventId + "/logo.png"
         const logoUrl = `organisations/${event.organisationId}/logo.png`;
         const s3Response = await Storage.get(logoUrl, {
            level: "public",
            download: true,
         });
         const logo = await s3Response.Body.arrayBuffer();
         const bytes = await printParticipationList(participations, event, logo);
         const url = URL.createObjectURL(new Blob([bytes]));
         const link = document.createElement("a");
         link.href = url;
         link.download = "participations-alphabetic.pdf";
         document.body.appendChild(link);
         link.click();
         link.remove();
      } catch (error) {
         console.log(error);
      }
   }

   if (editParticipation) {
      return <>
         <H3>Edit participation</H3>
         <FormGrid>
            <label htmlFor="firstName">Vorname</label>
            <Input type="text" idn="firstName" value={getValue(editParticipation, "firstName")} onChange={handleFieldChange} />
            <label htmlFor="lastName">Nachname</label>
            <Input type="text" idn="lastName" value={getValue(editParticipation, "lastName")} onChange={handleFieldChange} />
            <label htmlFor="email">Email</label>
            <Input type="email" idn="email" value={getValue(editParticipation, "email")} onChange={handleFieldChange} />
            <label htmlFor="language">Sprache</label>
            <Select idn="language" value={getValue(editParticipation, "language")} onChange={handleFieldChange}>
               <option value="de">DE</option>
               <option value="fr">FR</option>
            </Select>
            <div></div>
            <div>
               Kategorieneinteilung wird bei Änderung des Alters, Geschlechts oder der Kategorie nicht aktualisiert
            </div>
            <label htmlFor="age">Alter</label>
            <SelectAge idn="age" value={getValue(editParticipation, "age")} onChange={handleFieldChange} />
            <label htmlFor="gender">Geschlecht</label>
            <SelectGender idn="gender" value={getValue(editParticipation, "gender")} onChange={handleFieldChange} />
            <label htmlFor="category">Kategorie</label>
            <SelectCategory idn="category" value={getValue(editParticipation, "category")} onChange={handleFieldChange} />
            <label htmlFor="club">Verein</label>
            <Input type="text" idn="club" value={getValue(editParticipation, "club")} onChange={handleFieldChange} list="clublist" />
            <datalist id="clublist">
               <option>Arc Bleu</option>
               <option>Arc-Club Collombey-Muraz (ACCM)</option>
               <option>Arc Club Crans Montana</option>
               <option>Arc-Club Fribourg</option>
               <option>Arc Club Genève</option>
               <option>Arc Club Moléson-sur-Gruyères</option>
               <option>Arc et Forêt Tavannes</option>
               <option>Arc-ri Huttwil</option>
               <option>Archer Associés Apples</option>
               <option>Archers Club Yverdon</option>
               <option>Archers D'Engiadina Sur En-Sent/Scuol</option>
               <option>Archers du Gros-de-Vaud</option>
               <option>Archery Black Arrows (ABA)</option>
               <option>Archery Swiss Gossau</option>
               <option>Arcieri Club Locarno</option>
               <option>Arcieri del Bellinzonese</option>
               <option>Associazione Tiro Arco Luganese (ATAL)</option>
               <option>Baselbieter Bogenschützen</option>
               <option>BärnerBogenSportClub (BBSC)</option>
               <option>BC Burg Rosenegg</option>
               <option>BC Spirit</option>
               <option>Bear Hunters Bern</option>
               <option>Big Rock Longbow</option>
               <option>Black Sheep Archers</option>
               <option>Bogenclub Arjuna</option>
               <option>Bogenclub Bodensee</option>
               <option>Bogenclub Suhrental</option>
               <option>Bogenclub Thurland</option>
               <option>Bogenclub Turicum</option>
               <option>Bogenclub Uri</option>
               <option>Bogenfüchse Volketswil</option>
               <option>Bogenschmiede</option>
               <option>Bogenschützen Aarberg</option>
               <option>Bogenschützen Augusta</option>
               <option>Bogenschützen Baden (BC Baden)</option>
               <option>Bogenschützen Beider Basel (BSBB)</option>
               <option>Bogenschützen Biberist (BS Biberist)</option>
               <option>Bogenschützen Büsserach (BS Büsserach)</option>
               <option>Bogenschützen Davos/Klosters</option>
               <option>Bogenschützen Dübendorf</option>
               <option>Bogenschützenverein Düdingen</option>
               <option>Bogenschützen Fricktal</option>
               <option>Bogenschützen Geronimo</option>
               <option>Bogenschützen Grasburg</option>
               <option>Bogenschützen Hochsal e.V.</option>
               <option>Bogenschützen Interlaken (BSI)</option>
               <option>Bogenschützen Langnau</option>
               <option>Bogenschützen Lyss</option>
               <option>Bogenschützen Lyssach</option>
               <option>Bogenschützen Nidwalden</option>
               <option>Bogenschützen Oberaargau</option>
               <option>Bogenschützen Obwalden (BSC Obwalden)</option>
               <option>Bogenschützen Pilatus Luzern</option>
               <option>Bogenschützen Root-Zug</option>
               <option>Bogenschützen Schwarzsee</option>
               <option>Bogenschützen Solothurn</option>
               <option>Bogenschützen Sonnenbühl (BSS)</option>
               <option>Bogenschützen Thalwil (BST)</option>
               <option>Bogenschützen Thun (BS Thun)</option>
               <option>Bogenschützen Winterthur</option>
               <option>Bogenschützen Wolfensberg</option>
               <option>Bogenschützen Zürich</option>
               <option>Bogenschützen Zürich Oberland (BSZO)</option>
               <option>Bogenschützen-Club Chur (BSC Chur)</option>
               <option>Bogenschützen-Club Pfäffikon</option>
               <option>Bogenschützen-Club Zofingen (BSCZ)</option>
               <option>Bogenschützenclub Aquila</option>
               <option>Bogenschützenclub Glarnernland (BSC Glarnerland)</option>
               <option>Bogenschützenclub Olten (BSC Olten)</option>
               <option>Bogenschützenclub Spreitenbach (BSC-Spreitenbach)</option>
               <option>Bogenschützenclub Wide-Bremgarten</option>
               <option>Bogenschützenklub Forst Bern (BS Forst)</option>
               <option>Bogenschützenverein Bern (BV Bern)</option>
               <option>Bogenschützenverein Biel (BSV-CTA Biel-Bienne)</option>
               <option>Bogenschützenverein Rotten</option>
               <option>Bogenschützenverein Seeland</option>
               <option>Bogensportverein Obersee (BSV Obersee)</option>
               <option>Bogensportzentrum Hochrhein</option>
               <option>Bow Hunter Seeland</option>
               <option>Bow Hunting Club Graubünden</option>
               <option>Broken Arrow Archers</option>
               <option>BS Red Fox Zug</option>
               <option>BS Root-Zug</option>
               <option>BSC Lustenau - Österreich</option>
               <option>BSS Sonnenbühl</option>
               <option>BSV Indian Archers Brigerbärg</option>
               <option>BSV Vaduz</option>
               <option>BSZZ Bogensportzentrum Zürich</option>
               <option>Compagnie d'Arc de Diane</option>
               <option>Compagnons de l'Arc – Genève</option>
               <option>Compagnie des Archers de la Tour</option>
               <option>Compagnie des Archers de Lausanne</option>
               <option>Compagnie des Archers de Gland</option>
               <option>Compagnie des Archers de Roccans</option>
               <option>Compagnie des Archers de Vevey – La Tour-de-Peilz</option>
               <option>Compagnons de Sherwood La Chaux-de-Fonds</option>
               <option>Crazy Horse Bogenschützen Dietikon</option>
               <option>Die Walliser Bogenbrecher</option>
               <option>FB Huggerwald</option>
               <option>Geronimo Bogenclub Koblenz</option>
               <option>Highland Archers</option>
               <option>Hunting Arrows – BärnerBogeClub</option>
               <option>Juventas Basel - Riehen</option>
               <option>Les Amis de l'Arc La Heutte</option>
               <option>Les Archers de Joux</option>
               <option>Les Archers de l'Adret</option>
               <option>Les Archers de la Saigne</option>
               <option>Les Archers des trois Aigles</option>
               <option>Les Archers Roccans Lavey</option>
               <option>Les Archers du Bisse</option>
               <option>Les Ours Blancs</option>
               <option>Les Robins Avully-Genève</option>
               <option>Longbow Forest</option>
               <option>Longbow Moskitos Bogenschützenclub</option>
               <option>Longbow Team Minseln</option>
               <option>Northwest Archers</option>
               <option>Oberwalliser Bogenschützenverein</option>
               <option>Ostschweizer Bogenschützen</option>
               <option>Redback Archers</option>
               <option>Sagittaire Genève</option>
               <option>Società di Tiro con l'Arco Mendrisio</option>
               <option>Sporting Chiasso</option>
               <option>SSV Unteröwisheim</option>
               <option>Swiss Archers</option>
               <option>Tir à l'Arc Geneveys-sur-Coffrane (TAG)</option>
               <option>Tir a l'Arc La Chaux-de-Fonds</option>
               <option>Urbögler</option>
               <option>Zürcher Unterländer Bogenschützen (ZUBS)</option>
            </datalist>
            <label htmlFor="group">Gruppenwunsch</label>
            <div>
               <Input type="text" idn="group" value={getValue(editParticipation, "group")} onChange={handleFieldChange} /><br />
               Startgruppe wird bei Änderung nicht automatisch aktualisiert
            </div>
            <label htmlFor="notes">Bemerkungen</label>
            <TextArea idn="notes" value={getValue(editParticipation, "notes")} onChange={handleFieldChange} />
            {editParticipation.waitList &&
               <>
                  <label htmlFor="waitList">Warteliste</label>
                  <Select idn="waitList" value={getValue(editParticipation, "waitList")} onChange={handleFieldChange}>
                     <option value={false}>N</option>
                     <option value={true}>Y</option>
                  </Select>
               </>
            }
            <CancelButton onClick={cancelSubmit}>Cancel</CancelButton>
            <LoaderButton onClick={handleSubmit} loading={loading}>Save</LoaderButton>
         </FormGrid>
      </>;
   }

   return <>
      {participations.length > 0 ? (
         <>
            <H3>Participations</H3>
            <div className="mb-2">
               <IconButton
                  onClick={() => printPdf(participations)}
                  disabled={loading}
                  className="ml-2">
                  <PrinterIcon className="inline h-5" />Teilnehmerliste drucken
               </IconButton>
            </div>
            <div>
               <span>
                  Sort: <Select idn="sort" value={sort} onChange={sortParticipations}>
                     <option value="name">Name</option>
                     <option value="id">Id</option>
                     <option value="category">Category</option>
                     <option value="club">Club</option>
                     <option value="date">Date</option>
                     <option value="birthday">Birthday</option>
                     <option value="paid">Paid</option>
                     <option value="waitlist">Waitlist</option>
                  </Select>
               </span>
               <span className="ml-2">
                  <CSVButton data={csv} filename="participations.csv">CSV</CSVButton>
               </span>
            </div>
            <Table>
               <TableHead>
                  <TableHeadCell></TableHeadCell>
                  <TableHeadCell title="Anmeldungsnummer">#</TableHeadCell>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Vorname</TableHeadCell>
                  <TableHeadCell>Email</TableHeadCell>
                  <TableHeadCell title="Sprache">S</TableHeadCell>
                  <TableHeadCell>Geburt</TableHeadCell>
                  <TableHeadCell>Alter</TableHeadCell>
                  <TableHeadCell title="Geschlecht">M/F</TableHeadCell>
                  <TableHeadCell title="Bogenkategorie">Bogen</TableHeadCell>
                  <TableHeadCell>Club</TableHeadCell>
                  <TableHeadCell title="Gruppenwunsch (# eines anderen Teilnehmers)">GW</TableHeadCell>
                  <TableHeadCell>FAAS #</TableHeadCell>
                  <TableHeadCell title="FAAS Target Captain">TC</TableHeadCell>
                  <TableHeadCell>Bemerkungen</TableHeadCell>
                  <TableHeadCell title="Warteliste">W</TableHeadCell>
                  <TableHeadCell title="Geld eingezahlt">$</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
               </TableHead>
               <TableBody>
                  {participations.map((participation, key) => {
                     return (
                        <tr key={participation.participationId} className={key % 2 ? "bg-gray-100" : ""}>
                           <TableCell>{key + 1}</TableCell>
                           <TableCell>{participation.participationId}</TableCell>
                           <TableCell title={participation.lastName}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.lastName}</ExpanderBox>
                           </TableCell>
                           <TableCell title={participation.firstName}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.firstName}</ExpanderBox>
                           </TableCell>
                           <TableCell title={participation.email}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.email}</ExpanderBox>
                           </TableCell>
                           <TableCell>{participation.language}</TableCell>
                           <TableCell>{format(new Date(participation.birthday), "dd.MM.yyyy")}</TableCell>
                           <TableCell>{participation.age}</TableCell>
                           <TableCell>{participation.gender}</TableCell>
                           <TableCell>{participation.category}</TableCell>
                           <TableCell title={participation.club}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.club}</ExpanderBox>
                           </TableCell>
                           <TableCell title={participation.group}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.group}</ExpanderBox>
                           </TableCell>
                           <TableCell>{participation.faasId}</TableCell>
                           <TableCell>{participation.faasCaptain}</TableCell>
                           <TableCell title={participation.notes}>
                              <ExpanderBox maxWidth="max-w-4xs">{participation.notes}</ExpanderBox>
                           </TableCell>
                           <TableCell>{participation.waitList ? "Y" : "N"}</TableCell>
                           <TableCell>
                              <Select
                                 idn={"paid-" + key}
                                 value={participation.paid}
                                 disabled={updatingPaid}
                                 data-key={key}
                                 onChange={(e) => changePaid(e, participation)}>
                                 <option value="N">N</option>
                                 <option value="Y">Y</option>
                              </Select>
                           </TableCell>
                           <ToolCell>
                              <EditButton onClick={() => setEditParticipation(participation)} />
                              <DeleteButton onClick={() => deleteParticipation(participation)} />
                           </ToolCell>
                        </tr>
                     )
                  })}
               </TableBody>
            </Table>
         </>
      ) : (
         <p>{loading ? "Loading ..." : "No participations found"}</p>
      )}
   </>;
}